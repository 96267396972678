@font-face {
  font-family: KumbhSans-Light;
  src: url('../../fonts/KumbhSans-Light.ttf');
}

@font-face {
  font-family: KumbhSans-Regular;
  src: url('../../fonts/KumbhSans-Regular.ttf');
}

@font-face {
  font-family: KumbhSans-Bold;
  src: url('../../fonts/KumbhSans-Bold.ttf');
}

@font-face {
  font-family: Chelsea-Regular;
  src: url('../../fonts/Chelsea-Regular.ttf');
}

// @font-face {
//   font-family: GoudyStoutRegular;
//   src: url('../../fonts/Goudy-Stout-Regular.ttf');
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   color: #fff;
// }
.f_10 {
  font-size: 10px !important;
}
.f_12 {
  font-size: 12px !important;
}

.f_14 {
  font-size: 14px !important;
}

.f_15 {
  font-size: 15px !important;
}

.f_16 {
  font-size: 16px !important;
}

.f_18 {
  font-size: 18px !important;
}

.f_20 {
  font-size: 20px !important;
}

.f_22 {
  font-size: 22px !important;
}

.f_24 {
  font-size: 24px !important;
}

.f_26 {
  font-size: 26px !important;
}

.f_28 {
  font-size: 28px !important;
}

.f_30 {
  font-size: 30px !important;
}

.f_32 {
  font-size: 32px !important;
}
.f_40 {
  font-size: 40px !important;
}

.f_54 {
  font-size: 54px !important;
}

.fw_light {
  font-family: KumbhSans-Light !important;
}

.fw_regular {
  font-family: KumbhSans-Regular !important;
}

.fw_bold {
  font-family: KumbhSans-Bold !important;
}

.fw_che_regular {
  font-family: Chelsea-Regular !important;
}

:focus {
  outline: none !important;
  box-shadow: none !important;
}
.relative {
  position: relative;
  // position:absolute;
}
.margin_left {
  margin-left: -100px;
}
.absolute {
  position: absolute;
  right: 10px;
  bottom: -250px;
  width: 15vw;
  height: 100%;
}
.tableFixHead {
  overflow-y: auto !important;
  height: 320px !important;
}
.tableFixHead thead th {
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
}
.tableFixHead tbody th {
  position: sticky !important;
  left: 0 !important;
}
.tableFixHead thead tr {
  z-index: 999 !important;
}
th {
  background: #f2faff !important;
}
.required-asterisk {
  color: #dc3545;
  position: absolute;
  font-weight: bold;
  margin-left: 3px;
  font-size: 1.3em;
  right: 0%;
  top: 19%;
}
body {
  font-family: KumbhSans-Regular;
  color: #222222;
}

.pt_9 {
  padding-top: 90px;
}

.text_gray,
.text_gray {
  color: #707070 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.text_gray span {
  color: #107dd6 !important;
  cursor: pointer;
}

.text_gray2,
.text_gray2 {
  color: #656565 !important;
}

.text_gray3 {
  color: #818181 !important;
}

.text_green {
  color: #67dd9c !important;
}
.text_white {
  color: #fff !important;
}
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  background: #e9e606f0 !important;
  padding: 2px !important;
  width: 30px !important;
  border-radius: 15px !important;
  text-align: center;
  color: #ffff;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: #ffff !important;
}
.text_black,
.text_black:hover {
  color: #707070 !important;
}
.left_radius {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.right_radius {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.text_blue,
.text_blue:hover {
  color: #005182 !important;
}
.div_disabled {
  pointer-events: none;
  opacity: 0.7;
}
.comments {
  position: absolute;
  right: -140px;
  bottom: -213px;
  width: 15vw;
  height: 100%;
}
.syllabus {
  height: 650px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.border_color {
  // border-color:  #005182 !important;;
  border: 1px solid #005182 !important;
}
.slotheight {
  height: 500px;
}
.bg_pink {
  background-color: hsl(0, 52%, 96%) !important;
}
// .bg_gray
.bgColor {
  background-color: #ffffff;
}
// .bg_blue
.text_dark,
.text_dark:hover {
  color: #303030 !important;
}

.text_skyblue,
.text_skyblue:hover {
  color: #009ef8;
}

.text_lightblue,
.text_lightblue:hover {
  color: #6e9ebc;
}

.text_orange {
  color: #f66962 !important;
}

.text_blue2 {
  color: #6290f6 !important;
}

.text_purple {
  color: #bb67f4 !important;
}

.banner_bg {
  background: #f9fdff;
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
}
.bg_green {
  background-color: #67dd9c !important;
}
.bg_red {
  background-color: #f66962 !important;
}
.welcome_header_bg2 {
  background: transparent;
  height: 220px;
}

.banner_bg:before {
  content: '';
  // background: url('../../images/banner_bg.png') no-repeat;
  height: -webkit-fill-available;
  background-position: right;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: auto;
}

.custom_lang_select {
  border: none;
  color: #656565;
  width: 110px;
}

.custom_nav .nav-link {
  color: #005182 !important;
  font-family: KumbhSans-Regular !important;
  opacity: 1 !important;
}
.navbar .form-check-input {
  height: 25px !important;
  width: 50px;
  border-radius: 29px !important;
}
.navbar .form-check-input:checked {
  border-color: #67dd9c;
}

.custom_group .btn,
.custom_group .btn:hover {
  background: #f66962;
  color: white;
  border-color: #f66962;
  padding: 10px 20px;
}

.login_btn,
.login_btn:hover {
  background: white;
  color: #0a9ef8;
  border-color: #0a9ef8;
  width: 120px;
  border-radius: 3px;
}

.signup_btn .btn,
.signup_btn .btn:hover {
  background: #67dd9c;
  border-color: #67dd9c;
  color: white;
  width: 120px;
  border-radius: 3px;
  box-shadow: 0px 6px 9px #16a45617;
  height: 100%;
}

.signup_btn .dropdown-item,
.dropdown-item:hover {
  font-size: 16px;
  color: #777777;
  border-bottom: 1px solid #f6f6f6;
  padding: 8px 15px 6px 15px;
  background: white;
}

.border_bottom_none {
  border-bottom: none !important;
}

.signup_btn .dropdown-menu.show {
  display: block;
  border: none;
  box-shadow: 0px 4px 6px #00000014;
  border-radius: 5px;
  padding: 1px;
}

.custom_group {
  box-shadow: 0px 1px 20px #0000000f;
}

.custom_group input,
.custom_group input:focus {
  border-radius: 0px;
  border-color: white;
  border-width: 1px;
  border-right: 0px;
}

.custom_group button {
  border-radius: 0px;
}

.section_padding {
  padding: 80px 0;
}

.banner_text h1 {
  font-size: 34px;
}

.theme_btn,
.theme_btn:hover {
  background: #ff554b;
  color: white;
  border-color: #ff554b;
  padding: 10px 20px;
  border-radius: 0px;
  box-shadow: 0px 4px 15px #98070026;
  font-size: 18px;
}

.gray_btn,
.gray_btn:hover {
  background: #f0f0f0;
  color: #005182;
  border-color: #f0f0f0;
  padding: 10px 20px;
  font-size: 18px;
}
.gray1_btn,
.gray1_btn:hover {
  background: #faed98;
  color: #005182;
  border-color: #f0f0f0;
  padding: 10px 45px;
  font-size: 18px;
}

.nav_active {
  background: #fff;
  z-index: 999;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
  transition: ease-in-out 0.4s;
  padding: 8px 0px !important;
}

.custom_nav_tabs .active {
  background: white !important;
  border-radius: 0px;
  box-shadow: 0px 10px 27px #0000000a;
}

.custom_nav_tabs .nav-link {
  padding: 15px;
  background: white;
  transition: all 0.4s;
}

.custom_nav_tabs h4 {
  color: #005182;
}

.custom_nav_tabs .active h4 {
  color: #0a9ef8;
  font-family: KumbhSans-Bold;
}

.custom_nav_tabs span {
  background: #dcdcdc17;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  margin-right: 10px;
}

.custom_nav_tabs .nav-item {
  margin-bottom: 20px;
}

.custom_nav_tabs .active span {
  background: #0a9ef81a !important;
}

.custom_nav_tabs .active .tab svg path {
  stroke: #0a9ef8;
}

.custom_nav_tabs .active .tab2 svg path {
  fill: #0a9ef8;
}

.custom_nav_tabs .active .tab3 svg path {
  stroke: #0a9ef8;
}

.custom_nav_tabs .active .tab4 svg path {
  fill: #0a9ef8;
}

.custom_nav_tabs .active .tab5 svg path {
  stroke: #0a9ef8;
}

.plan_card {
  background: #f0f9ff;
  border-radius: 20px;
  padding: 40px;
}

.plan_nav_tabs .nav-link {
  color: #707070;
  border-radius: 50px;
  padding: 12px 40px;
}

.plan_nav_tabs {
  width: max-content;
  background: white;
  border-radius: 50px;
  box-shadow: 0px 1px 8px #0000001c;
  display: inline-flex;
  margin: 0 auto;
}

.plan_nav_tabs .nav-link.active {
  color: #fff;
  background-color: #67dd9c !important;
  border-radius: 50px !important;
  font-family: KumbhSans-Bold;
}

span.checkbox_tick {
  border: 1px solid #0a9ef8;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  position: absolute;
  right: 0;
  bottom: 0;
}

span.checkbox_tick svg {
  display: none;
}

.plan_card .form-check-input:checked + .form-check-label .checkbox_tick svg {
  display: flex !important;
  width: 12px;
  height: 12px;
  transition: all 0.5s;
}

.plan_card .form-check-input:checked + .form-check-label .checkbox_tick {
  background: #0a9ef8 !important;
  transition: all 0.4s;
}

.plan_card .form-check label {
  cursor: pointer;
  margin-bottom: 32px;
  transition: all 0.4s;
}

.form-check-input:checked + .form-check-label {
  font-family: KumbhSans-Bold;
  transition: all 0.4s;
}

.plan_right_side label {
  background: white;
  border: 1px solid #cecece;
  padding: 20px;
  border-radius: 10px;
}

.plan_right_side .form-check-input:checked + .form-check-label {
  background: #0a9ef8 !important;
  transition: all 0.4s;
  border-color: #0a9ef8 !important;
  color: white !important;
}

.plan_right_side .checkbox_tick2 {
  border: 1px solid #707070;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  position: relative;
  top: -1px;
}

.plan_right_side svg {
  display: flex !important;
  width: 12px;
  height: 12px;
  transition: all 0.5s;
}

.plan_right_side .form-check-input:checked + .form-check-label .checkbox_tick2 svg path {
  fill: #67dd9c !important;
}

.plan_right_side .form-check-input:checked + .form-check-label .checkbox_tick2 {
  background: #fff !important;
  border-color: #fff !important;
}

.plan_right_side .plan_charges {
  font-size: 35px;
  font-family: KumbhSans-Bold;
}

.plan_right_side .form-check-input:checked + .form-check-label .text_blue {
  color: white !important;
}

.plan_savings {
  background: #fef0ef;
  color: #f66962;
  padding: 7px 20px;
  border-radius: 50px;
  font-size: 14px;
}

.plan_right_side {
  width: 80%;
}

.plan_right_side label {
  margin-bottom: 20px;
}

.organisation_bg {
  background: url('../../images/organisation_bg.png') no-repeat;
  height: -webkit-fill-available;
  background-position: bottom;
  background-size: cover;
}

.organisation_bg_section img {
  border-radius: 50%;
}
.editIconPosition {
  position: absolute;
  right: 30px;
  bottom: -10px;
}
.text_blue2,
.text_blue2:hover {
  color: #0a9ef8 !important;
}

.text_blue3,
.text_blue3:hover {
  color: #2c6384 !important;
}

.mt-8 {
  margin-top: 10%;
}

.download_bg {
  background: #fff7f6;
  border-radius: 30px;
  z-index: 9;
  position: relative;
  top: -200px;
}

.ios_btn,
.ios_btn:hover {
  background: #000000;
  padding: 8px 24px;
  border: none;
  border-radius: 6px;
}

.android_btn,
.android_btn:hover {
  background: #ffffff30 !important;
  padding: 10px 25px;
  border: none;
  border-radius: 70px;
}

.ios_btn p,
.android_btn p {
  font-size: 10px;
}

.ios_btn p span,
.android_btn p span {
  font-size: 16px;
  font-family: 'KumbhSans-Bold';
}

.ios_btn p {
  position: relative;
  top: 2px;
}

.download_bg:before {
  content: '';
  background: url('../../images/download_bg.png') no-repeat;
  height: -webkit-fill-available;
  background-position: left;
  position: absolute;
  left: 0;
  right: 0;
  top: -13px;
  bottom: auto;
  z-index: -9;
}

footer {
  background: #f9fdff;
  margin-top: 15%;
}

footer .list-unstyled li {
  margin-bottom: 10px;
}

footer .list-unstyled li a {
  text-decoration: none;
}

.social_btns span {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 12px;
  border: 2px solid white;
}

.google_btn {
  background: #ea4335;
}

.fb_btn {
  background: #496fed;
}

.apple_btn {
  background: #000000;
}

.insta_btn {
  background: rgb(186, 0, 176);
  background: linear-gradient(143deg, rgba(186, 0, 176, 1) 0%, rgba(254, 197, 31, 1) 100%);
}

.linkedin_btn {
  background: #067fbf;
}

.twitter_btn {
  background: #009ff4;
}

.social_btns span svg {
  width: 15px;
  height: 15px;
}

.hr_edit {
  background: #0000001f;
  opacity: 1;
  margin: 0;
}

.login_img img {
  border-radius: 30px;
  box-shadow: 12px 0px #67dd9c;
}

.login_card {
  background: white;
  box-shadow: 1px 4px 6px #00000024;
  border-radius: 40px;
  padding: 20px;
  margin-top: -12%;
}

.signup_blue_btn,
.signup_blue_btn:hover {
  background: #0a9ef8;
  border-color: #0a9ef8;
  padding: 14px;
  border-radius: 60px;
}

.signup_white_btn,
.signup_white_btn:hover {
  background: white;
  border-color: white;
  box-shadow: 0px 1px 6px #00000012;
  padding: 14px;
  border-radius: 60px;
}

.or_lines:after {
  width: 38%;
  height: 1px;
  content: '';
  position: absolute;
  top: 9px;
  background: #d9d9d9;
  left: 0;
}
.fontWight {
  font-weight: 100;
}
.or_lines::before {
  width: 38%;
  height: 1px;
  content: '';
  position: absolute;
  top: 9px;
  background: #d9d9d9;
  right: 0;
}

.login_text {
  padding: 20px 40px 20px 40px;
}

.login_google_btn,
.login_google_btn:hover {
  background: #ea4335;
  border: #ea4335;
  padding: 8px 18px;
  border-radius: 50px;
  line-height: 0.5;
}

.login_fb_btn,
.login_fb_btn:hover {
  background: #4460a0;
  border: #4460a0;
  padding: 8px 16px;
  border-radius: 50px;
  line-height: 0.5;
}

.login_apple_btn,
.login_apple_btn:hover {
  background: #000;
  border: #000;
  padding: 8px 16px;
  border-radius: 50px;
  line-height: 0.5;
}

.login_linkedin_btn,
.login_linkedin_btn:hover {
  background: #0077b5;
  border: #0077b5;
  padding: 8px 16px;
  border-radius: 50px;
  line-height: 0.5;
}

.signup_social_icons svg {
  width: 30px;
  height: 18px;
  position: relative;
  top: -2px;
}

.login_sign_text {
  color: #656565;
}

.login_sign_text a {
  color: #0a9ef8;
}

.login_img_text {
  position: absolute;
  top: 40%;
  color: white;
  margin: 0 auto;
  display: block;
  width: 100%;
}

.custom_form .form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3rem + 2px) !important;
  padding: 0.5rem 0.75rem !important;
  border-radius: 8px;
  border-color: #b2b8c3;
}

.custom_form .form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 1;
  transform: scale(0.85) translateY(-1.6rem) translateX(0.15rem);
  background: white;
  height: 60%;
  padding: 14px 4px 14px 8px;
  margin-left: 5px;
}

.custom_form .form-floating > label {
  padding: 0.9rem 0.75rem;
}

.custom_form input {
  color: #005182;
  font-family: KumbhSans-Bold;
}

.custom_form input:focus {
  border-color: #009ef8 !important;
  color: #0a9ef8 !important;
}

span.eye_icon {
  position: absolute;
  top: 13px;
  right: 13px;
}

.login_box_height {
  height: calc(90vh - 100px);
  overflow-y: auto;
}

.login_box_height::-webkit-scrollbar {
  width: 4px;
}

.login_box_height::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.login_box_height::-webkit-scrollbar-thumb {
  background: #dbdbdb;
}

.login_box_height::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}

a {
  text-decoration: none;
}

.forgot_img img,
.otp_img img,
.reset_img img {
  box-shadow: none !important;
}

.forgot_img {
  background: #fff1f1;
  border-radius: 30px;
  height: calc(80vh - 100px);
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.forgot_img img,
.otp_img img,
.reset_img img {
  height: auto !important;
  width: auto !important;
}

.otp_img {
  background: #f5fffa;
  border-radius: 30px;
  height: calc(80vh - 100px);
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login_img.text-center.forgot_img {
  color: #f66962;
}

.otp_img p {
  color: #67dd9c;
}

form.otp_input input {
  background: #f9f9f9;
  border-color: #f9f9f9;
  border-radius: 5px;
  margin-right: 20px;
  height: 50px;
  width: 50px;
  text-align: center;
  font-size: 17px;
  font-family: KumbhSans-Bold;
}

form.otp_input input:focus {
  background: #67dd9c;
  border-color: #67dd9c;
  box-shadow: 0px 4px 12px #67dd9c;
  color: white !important;
}

.text_red,
.text_red:hover {
  color: #f66962;
}

.reset_img {
  background: #fffeec;
  border-radius: 30px;
  height: calc(80vh - 100px);
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reset_img p {
  color: #fdd381;
}

.welcome_header_bg {
  background: #f9fdff;
  height: 220px;
}

header .enrollbtn {
  width: 100px;
  height: 38px;
  background-color: #fff3f3;
  border-radius: 70px;
  border: 1px solid #ffe9e8;
  line-height: 1;
}

.header_after_login span {
  // background: #f8f8f8;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-right: 10px;
}

.user_profile_img {
  width: 50px;
  height: 50px;
}

.user_profile_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
  box-shadow: 0px 3px 6px #00000012;
}

.dropdown {
  list-style-type: none !important;
}

.dropdown_menu {
  position: absolute;
  top: 52px;
  right: 0;
  width: 280px;
  perspective: 1000px;
  z-index: -1;
  background: white;
  border-radius: 7px;
  box-shadow: 0px 3px 6px #00000026;
  padding-left: 0;
  margin-top: -10px;
}

.dropdown_menu li {
  display: none;
  color: #005182;
  padding: 10px 20px;
  font-size: 16px;
  opacity: 0;
  border-bottom: 1px solid #dedede63;
}

.dropdown_menu li:hover {
  background-color: #fff;
}

.dropdown_menu li:last-child {
  border: none;
}

.dropdown:hover .dropdown_menu--animated {
  display: block;
}

.dropdown_menu--animated {
  display: none;
}

.dropdown_menu--animated li {
  display: block;
  opacity: 1;
}

.dropdown_menu-4 .dropdown_item-1 {
  transform-origin: top center;
  animation: scaleZ 300ms 60ms ease-in-out forwards;
}

.dropdown_menu-4 .dropdown_item-2 {
  transform-origin: top center;
  animation: scaleZ 300ms 120ms ease-in-out forwards;
}

.dropdown_menu-4 .dropdown_item-3 {
  transform-origin: top center;
  animation: scaleZ 300ms 180ms ease-in-out forwards;
}

.dropdown_menu-4 .dropdown_item-4 {
  transform-origin: top center;
  animation: scaleZ 300ms 240ms ease-in-out forwards;
}

.dropdown_menu-4 .dropdown_item-5 {
  transform-origin: top center;
  animation: scaleZ 300ms 300ms ease-in-out forwards;
}

.dropdown_menu-4 .dropdown_item-6 {
  transform-origin: top center;
  animation: scaleZ 300ms 360ms ease-in-out forwards;
}

.dropdown_menu-4 .dropdown_item-7 {
  transform-origin: top center;
  animation: scaleZ 300ms 420ms ease-in-out forwards;
}

.dropdown_menu-4 .dropdown_item-8 {
  transform-origin: top center;
  animation: scaleZ 300ms 180ms ease-in-out forwards;
}

.dropdown_menu-4 {
  transform-origin: top center;
  animation: scaleZ 100ms 480ms ease-in-out forwards;
}

@-moz-keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-o-keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-moz-keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-o-keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.dropdown {
  position: relative;
  perspective: 1000px;
  z-index: 9;
}

.dropdown:hover {
  cursor: pointer;
}

.dropdown:hover .dropdown_menu li {
  display: block;
}

.login_welcome {
  background: url('../../images/login_welcome.png') no-repeat;
  background-size: cover;
  background-position: center;
}

.chinese_btn,
.chinese_btn:hover {
  background: white;
  border-color: white;
  border-radius: 30px;
  padding: 8px 15px;
}

.white_outline_btn,
.white_outline_btn:hover {
  background: transparent;
  border-color: white;
  border-radius: 30px;
  padding: 8px 15px;
  color: white;
}

.dropdown_menu a {
  color: #005182;
}

.dropdown_menu a span {
  position: relative;
  top: -2px;
  margin-right: 8px;
}

.logout_color {
  color: #ff554b !important;
}

.custom_nav .active {
  color: #ff554b !important;
  font-family: 'KumbhSans-Bold' !important;
}

.filters_cards {
  background: white;
  padding: 30px;
  border-radius: 30px;
  box-shadow: 0px -1px 16px #00000012;
  margin: -80px auto;
  width: 100%;
  z-index: 9;
  margin-bottom: 40px;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #005182;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 22px;
  width: 22px;
  border: 1px solid #d3d3d3;
  border-radius: 3px !important;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: #67dd9c4d;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #67dd9c;
  border-radius: 3px;
  border-color: #67dd9c;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.filters_search span {
  position: absolute;
  top: 12px;
  left: 15px;
}

.filters_search input {
  border-color: #f1f1f1;
  border-radius: 10px;
  padding: 12px 20px 12px 40px !important;
  width: 22vw;
}

.filters_cards .dropdown .btn {
  background: #f8f8f8 !important;
  border-color: #e8e8e8 !important;
  color: #303030 !important;
}

.filters_cards .dropdown-menu.show {
  display: block;
  border: none;
  border-radius: 13px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 0;
  padding-bottom: 25px;
  width: 300px;
  top: 10px !important;
}

.filters_cards .dropdown-menu.show li {
  padding: 12px 20px;
  border-bottom: 1px solid #707070;
}

.filters_cards .dropdown-menu.show li:last-child {
  border-bottom: none !important;
}

.clear_filter {
  position: absolute;
  bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #f66962;
}

.filters_cards .dropdown-toggle::after {
  display: none !important;
}

.filters_tabs .active {
  color: #f66962 !important;
  font-family: 'KumbhSans-Bold' !important;
}

.filters_tabs span {
  color: #005182 !important;
  font-family: KumbhSans-Regular;
  font-size: 18px;
  margin-right: 10px;
  cursor: pointer;
}

.background_gray {
  background: #f8fcff;
}

.gray_bg {
  background-color: #ecf4f8 !important;
  color: #85939b !important;
}
.bg_gray {
  background-color: #808080 !important;
}
.bg_light_gray {
  background-color: #d3d3d3 !important;
}
.bg_dark_gray {
  background-color: #5a5a5a !important;
}
.background_darkgray {
  background: #fff6f5;
}

.profile_card {
  background: white;
  padding: 15px;
  box-shadow: 0px 0px 9px #0a9ef826;
  border-radius: 10px;
}

.profile_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 14px;
}

.heart_icon {
  position: absolute;
  top: 16px;
  right: 16px;
  background: white;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -2px 10px #7c7c7c38;
  border-radius: 40px;
  cursor: pointer;
}

.heart_icon_active {
  position: absolute;
  top: 16px;
  right: 16px;
  background: white;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -2px 10px #7c7c7c38;
  border-radius: 40px;
  cursor: pointer;
}

.heart_icon_active svg path {
  fill: #f52e24 !important;
}

span.country_info svg {
  border-radius: 3px;
}

.ratings svg {
  width: 14px;
  height: 14px;
}

span.categories span {
  background: #f8f8f8 !important;
  border-color: #e8e8e8 !important;
  color: #949494;
  padding: 10px 8px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid;
}

.profile_btns button,
.profile_btns a {
  width: 48%;
}

.message_btn,
.message_btn:hover {
  border-color: #2c6384 !important;
  color: #005182 !important;
  background: white !important;
  font-size: 14px;
  border-radius: 5px;
  padding: 10px;
}

.schedule_btn,
.schedule_btn:hover {
  border-color: #009ff4 !important;
  color: #0a9ef8 !important;
  background: white !important;
  font-size: 14px;
  border-radius: 5px;
  padding: 8px;
}

.call_btn,
.call_btn:hover {
  background: #28b572;
  border-color: #28b572 !important;
  color: #fff !important;
  font-size: 14px;
  border-radius: 5px;
  padding: 8px;
}

.profile_btns button svg {
  position: relative;
  top: -1px;
  width: 16px;
  height: 16px;
}

span.profile_online {
  background: #12bc1e;
  width: 14px;
  height: 14px;
  display: block;
  border-radius: 30px;
  border: 1px solid white;
  box-shadow: 0px 3px 6px #67dd9c69;
}

span.profile_online {
  background: #12bc1e;
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 30px;
  border: 1px solid white;
  box-shadow: 0px 3px 6px #67dd9c69;
  position: absolute;
  right: -4px;
  bottom: -4px;
}
span.profile_offline {
  background: #afafaf;
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 30px;
  border: 1px solid white;
  box-shadow: 0px 3px 6px #c1c1c1;
  position: absolute;
  right: -4px;
  bottom: -4px;
}

.profile_img {
  width: 76px;
  height: 100px;
  position: relative;
}

.filters_cards .has-megamenu {
  position: static !important;
  perspective: initial !important;
}

.filters_cards .megamenu {
  right: 0 !important;
  width: 900px !important;
  margin-top: 0 !important;
}

.filters_cards .megamenu.show {
  transform: translate(-145px, 46px) !important;
}

.events-container {
  overflow-y: scroll;
  height: 100%;
  width: 50%;
  margin: 0px auto;
  display: inline-block;
  padding: 0px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  padding: 0;
}

.events-container:after {
  clear: both;
}

.event-card {
  padding: 20px 0;
  max-width: 100%;
  display: block;
  background: #fff;
  border: none !important;
  margin: 20px;
  margin-left: 12px;
}

.event-count,
.event-name,
.event-cancelled {
  display: inline;
  padding: 0 10px;
  font-size: 1rem;
}

.event-count {
  color: #7e0cf5;
  text-align: right;
}

.event-name {
  padding-right: 0;
  text-align: left;
}

.event-cancelled {
  color: #cd4dcc;
  text-align: right;
}

.calendar-container {
  position: relative;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  font: 13px Helvetica, Arial, san-serif;
  display: inline-block;
  padding: 20px;
}

.calendar-container:after {
  clear: both;
}

.calendar {
  width: 100%;
  padding: 0;
}

.year-header {
  height: 40px;
  text-align: center;
  position: relative;
  color: #fff;
  border-top-left-radius: 3px;
  margin-top: 20px;
}

.year-header span {
  display: inline-block;
  font-size: 20px;
  line-height: 40px;
  color: #000;
}

.left-button,
.right-button {
  cursor: pointer;
  width: 28px;
  text-align: center;
  position: absolute;
  color: #cccccc !important;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-size: 14px !important;
}

.left-button:hover,
.right-button:hover {
  color: #7e0cf5 !important;
}

.left-button {
  left: 0;
}

.right-button {
  right: 0;
  top: 0;
}

.button {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  font-size: 1rem;
  border-radius: 25px;
  padding: 0.65rem 1.9rem;
  -webkit-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
  color: white;
  border: none;
  background: #7e0cf5;
}

.button.button-white {
  background: #fff;
  color: #000;
}

.button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

#cancel-button {
  background: #cd4dcc;
}
// .f_10
#add-button {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

#add-button:hover,
#ok-button:hover,
#cancel-button:hover {
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
}

#add-button:active,
#ok-button:active,
#cancel-button:active {
  -webkit-transform: translateY(3px) scale(0.97);
  -ms-transform: translateY(3px) scale(0.97);
  transform: translateY(3px) scale(0.97);
}

.days-table,
.dates-table,
.months-table {
  border-collapse: separate;
  text-align: center;
}

.day {
  height: 26px;
  width: 26px;
  padding: 0 10px;
  line-height: 26px;
  border: 2px solid transparent;
  text-transform: uppercase;
  font-size: 10px;
  color: #000;
}

.month {
  cursor: default;
  height: 26px;
  width: 26px;
  padding: 0 2px;
  padding-top: 10px;
  line-height: 26px;
  text-transform: uppercase;
  font-size: 11px;
  color: #cccccc;
  -webkit-transition: all 250ms;
  -o-transition: all 250ms;
  transition: all 250ms;
}

.active-month {
  font-weight: 700;
  color: #000;
}

.month:hover {
  color: #cd4dcc;
}

.table-date {
  cursor: default;
  color: #212121;
  height: 40px !important;
  width: 40px !important;
  display: table-cell;
  font-size: 15px;
  padding: 0;
  line-height: inherit;
  text-align: center;
  border-radius: 8px;
  border: 1px solid transparent;
  -webkit-transition: all 250ms;
  -o-transition: all 250ms;
  transition: all 250ms;
  position: relative;
}

.table-date:not(.nil):hover {
  border-color: #cd4dcc;
}

.event-date {
  border-color: #7e0cf5;
  background: #7e0cf5;
  color: #fff;
}

.event-date:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: '';
  background: #28a745;
}

.active-date {
  background: #0a9ef8;
  color: #fff;
  width: 40px !important;
  display: flex;
  height: 40px !important;
  margin: 0 auto;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}

.table-date:not(.nil):hover {
  background: #0a9ef8;
  border-color: #0a9ef8;
  color: #fff;
}

.busy_text {
  color: #bcaf12;
  font-family: 'KumbhSans-Bold';
  font-size: 12px;
  height: 20px;
}

.hours_text {
  font-size: 14px;
  color: #0fd4ca;
  font-family: 'KumbhSans-Bold';
  height: 20px;
}

.hours_text span {
  font-size: 10px;
  color: #707070;
  font-family: 'KumbhSans-Regular';
}

.not_visible {
  visibility: hidden;
}

.filters_tabs .nav-link.active,
.filters_tabs .show > .nav-link {
  color: #fff;
  background-color: #ffffff;
}

.filters_tabs .nav-link {
  padding: 0px 10px;
  color: #005182;
}

span.online_icon_active {
  position: relative;
  top: -3px;
  left: 4px;
  display: none;
  margin: 0;
}

.filters_tabs .nav-link.active .online_icon_active {
  display: inline-block;
}

.filters_tabs li {
  display: flex;
}

p.date_time {
  color: #949494;
  font-size: 10px;
}

.dropdown_arrow {
  background: #e5e5e5;
  width: 40px;
  height: auto;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

span.checbox_active {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #28b572;
  border-radius: 3px;
  margin-right: 5px;
}

span.checbox_inactive {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e5e5e5;
  border-radius: 3px;
}

.main_dropdown .dropdown-toggle {
  background: white;
  border: 1px solid #0a9ef8;
  padding: 6px;
  border-radius: 6px;
}

.main_dropdown .dropdown-toggle::after {
  display: none !important;
}

.main_dropdown .dropdown-menu.show {
  display: block;
  width: 400px;
  position: absolute;
  right: 0;
  left: 0;
  background: white;
  border-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000021;
}

.main_dropdown hr {
  background: #707070;
  margin: 0;
}

.mins_border {
  border-bottom: 3px solid #e3f4ff;
  padding-bottom: 6px;
}

.plan_details {
  background: #e3f4ff;
  padding: 12px;
}

.profile_tabs .nav-link {
  background: none;
  padding: 0;
  display: flex;
  padding-bottom: 10px;
}

.profile_tabs .nav-link.active,
.profile_tabs .show > .nav-link {
  color: #fff;
  background-color: #fff;
}

.profile_tabs {
  background: white;
  border-radius: 20px;
  padding: 20px 0px 20px 20px;
  box-shadow: 0px -1px 20px #0000001a;
}

.profile_tabs .nav-link p {
  color: #005182 !important;
  font-size: 16px;
  width: 100%;
  border-bottom: 1px solid #dfdfdf;
}

.profile_tabs .nav-link span {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.profile_tabs .active p {
  color: #009ff4 !important;
  font-size: 16px;
  font-family: 'KumbhSans-Bold';
}

.profile_tabs .active span .fill_svg path {
  fill: #009ff4;
}

.profile_tabs .active span .stroke_svg path {
  stroke: #009ff4;
}

.logout_text span {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.logout_text,
.logout_text:hover {
  color: #ff554b;
  display: flex;
  align-items: center;
}

.profile_tab .card-body {
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px -1px 20px #0000001a;
}

.profile_tab .card {
  border: none;
}

.theme_btn2,
.theme_btn2:hover {
  background: #ff554b;
  color: white;
  border-color: #ff554b;
  padding: 8px 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 15px #98070026;
  font-size: 18px;
}

.profile_info label {
  color: #005182;
  font-size: 16px;
  font-family: 'KumbhSans-Bold';
  margin-bottom: 10px;
}

.profile_info select,
input,
select {
  // border: 1px solid #d9d9d9 !important;
  border-radius: 8px !important;
  padding: 8px 15px !important;
  color: #005182 !important;
}

.profile_info .w_70 {
  width: 70%;
}

.react-pdf__Page__textContent .react-pdf__Page__canvas {
  width: 45vw !important;
}
.react-pdf__Page__textContent .react-pdf__Page__textContent {
  display: none;
}
.react-pdf__Page__textContent .react-pdf__Page__annotations {
  display: none;
}
.radio_box {
  font-family: 'KumbhSans-Regular';
  color: #85939b;
  min-width: 140px;
  border: 1px solid #eaeaea;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  text-align: center;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_radios .form-check-input:checked + .form-check-label .radio_box {
  border-color: #ff554b;
  color: #ff554b;
  transition: all 0.4s;
  font-family: 'KumbhSans-Bold';
}

.profile_radios .form-check-input:checked + .form-check-label .radio_box svg path {
  fill: #ff554b;
  transition: all 0.4s;
}

.radio_box svg {
  position: relative;
  top: -1px;
  margin-right: 8px;
}

.profile_radios .form-check-input:checked + .form-check-label .radio_box2 {
  border-color: #0a9ef8;
  color: #0a9ef8;
  transition: all 0.4s;
  font-family: 'KumbhSans-Bold';
}

.radio_box2 {
  font-family: 'KumbhSans-Regular';
  color: #85939b;
  min-width: 140px;
  border: 1px solid #eaeaea;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  text-align: center;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
}

.w_30 {
  width: 30% !important;
}
.w_100 {
  width: 100px !important;
}
.priority {
  height: 450px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.theme_blue_btn,
.theme_blue_btn:hover {
  background: white;
  color: #0a9ef8;
  border-color: #0a9ef8;
  padding: 8px 20px;
  border-radius: 4px;
  box-shadow: none;
  font-size: 18px;
}

.theme_blue_btn svg {
  position: relative;
  top: -1px;
  margin-right: 4px;
}

.delete_btn,
.delete_btn:hover {
  background: white;
  color: #ff554b;
  border-color: #ff554b;
  padding: 8px 10px;
  border-radius: 4px;
  box-shadow: none;
  font-size: 18px;
}

.delete_btn svg {
  position: relative;
  top: -2px;
  margin-right: 6px;
}

.pass_eye_icon {
  position: absolute;
  top: 8px;
  right: 14px;
  cursor: pointer;
}

.custom_group.mb-3 {
  border: 1px solid #d9d9d9 !important;
  border-radius: 8px !important;
  padding: 8px 15px !important;
  color: #005182 !important;
}

.no_input_border {
  border: none !important;
  padding: 0 !important;
}

.unverify_input .input-group-text {
  background: no-repeat;
  border: none;
  font-size: 14px;
  color: #ff554b;
  font-family: 'KumbhSans-Bold';
  padding: 0;
  cursor: pointer;
}

.verify_input .input-group-text {
  background: no-repeat;
  border: none;
  font-size: 14px;
  color: #0c9b30;
  font-family: 'KumbhSans-Bold';
  padding: 0;
  cursor: pointer;
}

.apply_input .input-group-text {
  border: none;
  font-size: 14px;
  color: #fff;
  font-family: 'KumbhSans-Bold';
  padding: 0;
  cursor: pointer;
  background: #67dd9c;
  border-radius: 8px;
  padding: 10px 20px;
}
.cursor {
  cursor: pointer;
}
.notifications_check input {
  width: 30px !important;
  height: 30px !important;
  display: block;
  background-color: #fff;
  border-color: #d9d9d9 !important;
  border-radius: 5px !important;
  margin-bottom: 10px;
}

.notifications_check label {
  width: 100%;
  border-bottom: 1px solid #707070;
  padding-bottom: 10px;
  margin-left: 10px;
}

.notifications_check .form-check {
  display: flex;
}

.profile_info .form-check-input:checked {
  background-color: #67dd9c;
  border-color: #67dd9c !important;
}

.unsubscribe_btn,
.unsubscribe_btn:hover {
  background: white;
  color: #ff554b;
  border-color: #005182;
  padding: 8px 10px;
  border-radius: 4px;
  box-shadow: none;
  font-size: 18px;
}

.theme_btn_blue,
.theme_btn_blue:hover {
  background: #009ff4;
  color: white;
  padding: 8px 10px;
  border-radius: 4px;
  font-size: 15px;
  border-color: #009ff4;
}

.theme_light_btn,
.theme_light_btn:hover {
  background: #ceedfd;
  color: #009ff4;
  padding: 8px 10px;
  border-radius: 4px;
  font-size: 15px;
  border-color: #ceedfd;
}

.certificate_img {
  background: #f8fcfe;
  padding: 30px;
}

.desktop_checkbox input {
  border: 1px solid #005182 !important;
  width: 22px !important;
  height: 22px !important;
  display: block;
  padding: 0 !important;
  border-radius: 4px !important;
  position: relative;
  top: -3px;
}

.desktop_checkbox label {
  margin: 0px !important;
  margin-left: 10px !important;
}

.hide_collapse a,
.hide_collapse a:hover,
.hide_collapse a:focus {
  background: none !important;
  color: #ff554b !important;
  border: none !important;
}

.tutors_collapse .card-body {
  background: #f2faff;
  box-shadow: none;
}

.tutor_img {
  width: 60px;
  height: 60px;
}

.tutor_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}

.tutor_info span {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 6px #00000042;
  border-radius: 30px;
  cursor: pointer;
}

.tutor_info {
  border-bottom: 1px solid #0a9ef830;
  padding-bottom: 20px;
}

.payment_tabs .nav-link.active,
.payment_tabs .show > .nav-link {
  color: #9f9f9f;
  background-color: #ffffff;
  border: 1px solid #0a9ef8;
  border-radius: 12px;
  padding: 10px 20px;
}

.payment_tabs .nav-link.active h5 {
  color: #0a9ef8;
  font-family: KumbhSans-Bold;
}

.payment_tabs .nav-link {
  color: #9f9f9f;
  background-color: #ffffff;
  border: 1px solid #bcbcbc;
  border-radius: 12px;
  padding: 10px;
}

.payment_tabs .nav-link h5 {
  line-height: 23px;
  margin-bottom: 5px;
  transition: all 0.4s;
}

.payment_tabs .nav-link {
  cursor: pointer;
}

.left_border_edit:after {
  content: '';
  width: 2px;
  height: 120px;
  position: absolute;
  background: #e2e2e2;
  top: 34%;
  display: block;
  right: -20px;
}

.payment_img {
  border: 1px solid #f5f5f5;
  padding: 14px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  width: 100%;
  height: 80px;
}

.payment_radios .form-check-input:checked + .form-check-label .payment_img {
  border-color: #ff554b;
  color: #ff554b;
  transition: all 0.4s;
  font-family: 'KumbhSans-Bold';
}

.mt_11 {
  margin-top: 11%;
}

.profile_details_img {
  width: 100%;
  height: 200px;
  position: relative;
}

.profile_details_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 14px;
}

.profile_details_btns button,
.profile_details_btns a {
  width: 150px;
}

.tutor_video_player {
  background: #eff9ff;
  border-radius: 25px;
}

.tutor_video_player video {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 22px;
}

.tutor_tag {
  background: #e6f5ff;
  padding: 10px 15px;
  display: inline-block;
  border-radius: 30px;
  font-size: 14px;
  color: #656565;
}

.owl-theme .owl-nav {
  margin-top: 10px;
  position: absolute;
  top: -50px;
  right: 0;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background: #ffffff !important;
  color: #f66962 !important;
  text-decoration: none;
}

.review_card {
  background: #eff9ff;
  border-radius: 18px;
}

.review_img {
  width: 55px;
  height: 55px;
}

.review_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}

.review_card span {
  color: #85939b;
}

.review_bottom_border {
  border-bottom: 1px solid #e3f5ff;
  padding-bottom: 10px;
}

.review_ratings span {
  background: #ffffff;
  border-radius: 6px;
  width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.review_ratings span svg {
  width: 12px;
  height: 12px;
}

.review_ratings .inactive svg path {
  fill: #fff0d5;
}

.review_card p {
  color: #2c6384;
  font-size: 14px;
}

.review_ratings:after {
  color: #ff554b;
  content: '“';
  font-size: 70px;
  position: absolute;
  right: 20px;
  top: -10px;
}

.text_underline {
  text-decoration: underline;
}
.Calendar__weekDays {
  color: #000 !important;
  font-weight: bold !important;
}
.Calendar__day.-ltr {
  color: #000 !important;
  font-weight: bold !important;
}
.calendar_bg {
  // background: #f9f9f9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}
.calenderClass {
  width: 70% !important;
}

.back_arrow {
  position: absolute;
  top: 0;
  left: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.time_selection {
  width: 80%;
  background: white;
  border-radius: 10px;
  padding: 15px;
  margin: 0 auto;
}

.booked_btn,
.booked_btn:hover,
.booked_btn:focus {
  background: #eaeaea;
  border-color: #eaeaea;
  color: #707070;
  font-size: 14px;
  border-radius: 0px;
  width: 100px;
  padding: 8px 0px;
}

.selected_btn,
.selected_btn:hover,
.selected_btn:focus {
  background: #f66962;
  border-color: #f66962;
  color: #fff;
  font-size: 14px;
  border-radius: 0px;
  width: 100px;
  padding: 8px 0px;
  font-family: KumbhSans-Bold;
}

.text_dropdown .dropdown-menu.show li {
  padding: 5px;
  border-bottom: 1px solid #e6e6e6;
}

.text_dropdown .dropdown-menu.show li a,
.text_dropdown .dropdown-menu.show li a:hover {
  color: #005182;
  font-size: 12px;
  padding: 5px 10px;
  border-bottom: none !important;
}

.reservation_details .reserve_icon {
  display: flex;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  align-self: flex-start;
}

.text_dropdown p {
  color: #0a9ef8;
}

.text_dropdown .dropdown-menu.show {
  display: block;
  border: none;
  border-radius: 13px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 0;
  padding-bottom: 0px;
  width: auto !important;
}

.selection_inner_width {
  width: 50%;
  margin: 0 auto;
}

.selection_inner_width .review_img {
  width: 45px;
  height: 45px;
}

.selection_inner_width textarea {
  border: 1px solid #eaeaea;
  border-radius: 6px;
}

.selection_inner_width textarea::placeholder {
  font-size: 14px;
}

.selection_inner_width textarea:-ms-input-placeholder {
  font-size: 14px;
}

.selection_inner_width textarea::-ms-input-placeholder {
  font-size: 14px;
}

.selection_inner_width .dropdown .btn {
  border: 1px solid #eaeaea !important;
  background: white !important;
  border-radius: 6px;
}

.w_70 {
  width: 70% !important;
}

.congrats_msg p {
  color: #67dd9c;
}

.congrats_msg {
  background: #e0f8eb;
  padding: 10px;
}

.dismiss_btn,
.dismiss_btn:hover {
  background: #ebf1f4;
  border-color: #ebf1f4;
  color: #005182;
  padding: 8px 20px;
  border-radius: 4px;
}

.custom_modal .modal-header {
  background: #eaf7fe;
}

.custom_modal .modal-header,
.custom_modal .modal-footer {
  border: none !important;
}

.custom_modal .btn-close {
  background-color: white;
  opacity: 1;
  border-radius: 60px;
  font-size: 12px;
  padding: 10px;
}

.custom_modal .modal-header h5 {
  flex-basis: 100%;
}

.custom_modal .modal-footer {
  justify-content: center;
}

.teacher_video_img {
  height: calc(100vh - 130px);
}

.teacher_video_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
}
.text_rating {
  color: #f2a60e;
  font-weight: 700;
}

.text_wrap table thead tr th {
  white-space: nowrap;
}

.text_wrap::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}

/* Track */
.text_wrap::-webkit-scrollbar-track {
  -moz-box-shadow: inset 0px 0px 20px #dbeffb;
  box-shadow: inset 0px 0px 20px #dbeffb;
  border-radius: 10px;
}

/* Handle */
.text_wrap::-webkit-scrollbar-thumb {
  background: #009ff4;
  border-radius: 10px;
  background-clip: padding-box;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}

/* Handle on hover */
.text_wrap::-webkit-scrollbar-thumb:hover {
  background: #009ff4;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}

.my_image {
  position: absolute;
  top: 50px;
  right: 20px;
  width: 180px;
  height: 180px;
}

.my_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 3px solid white;
  border-radius: 30px;
  box-shadow: 0px 15px 23px #0000001a;
}

.video_action_btn span {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 0px 3px 6px #0000003b;
  margin-right: 15px;
  cursor: pointer;
}

.video_action_btn span svg {
  width: 28px;
  height: 28px;
}

.msg_icon {
  background: #0a9ef8 !important;
}

.video_action_btn {
  position: absolute;
  bottom: 50px;
  width: 100%;
}

.mute {
  background: #0000005c;
}

.cut_call {
  background: #f66962;
}

.video_timer {
  position: absolute;
  right: 30px;
  bottom: 50px;
  background: #ffffff6e;
  padding: 10px 30px;
  border-radius: 15px;
}

.video_timer h4 {
  color: #0a9ef8;
  font-family: 'KumbhSans-Bold';
  position: relative;
  padding-left: 10px;
}

.video_timer h4:before {
  content: '';
  width: 16px;
  height: 16px;
  position: absolute;
  top: 4px;
  background: red;
  border-radius: 20px;
  left: -15px;
  border: 5px solid white;
}

.video_bg {
  background: white;
  padding: 30px;
  border-radius: 30px;
  box-shadow: 0px -1px 20px #00000024;
}

.video_setup_box label {
  color: #005182;
  font-family: 'KumbhSans-Bold';
}

.video_setup_box input::placeholder {
  color: #005182;
}

.video_setup_box input:-ms-input-placeholder {
  color: #005182;
}

.video_setup_box input::-ms-input-placeholder {
  color: #005182;
}

.video_setup_box span {
  color: #005182;
  font-family: 'KumbhSans-Bold';
}

.form_input_icon {
  position: absolute;
  top: 8px;
  left: 10px;
}

.video_setup_box input,
.video_setup_box select {
  padding-left: 35px !important;
}

.video_setup_box .dropdown_btn {
  border: 1px solid #d9d9d9 !important;
  border-radius: 8px !important;
  padding: 8px 15px !important;
  color: #005182 !important;
  background: white !important;
  padding-left: 35px !important;
}

.dropdown_btn::after {
  display: none !important;
}

.video_setup_box .dropdown-menu.show {
  display: block;
  border: none;
  border-radius: 13px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 0;
  padding-bottom: 0px;
  width: 100% !important;
}

.video_setup_box .text_dropdown .dropdown-menu.show li a,
.video_setup_box .text_dropdown .dropdown-menu.show li a:hover {
  color: #005182;
  font-size: 14px;
  padding: 5px 10px;
  border-bottom: none !important;
}

.video_setup_box {
  width: 80%;
  padding-left: 30px;
  padding-top: 20px;
}

.video_setup_box p {
  color: #6e9ebc;
}

.video_setup_action span {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 0px 3px 6px #0000003b;
  margin-right: 15px;
  cursor: pointer;
}

.video_setup_action span svg {
  width: 28px;
  height: 28px;
}

.video_setup_action .mute_icon {
  background: #ff1207 !important;
}

.video_setup_action {
  display: flex;
  background: #fff6f5;
  width: max-content;
  padding: 10px 30px;
  border-radius: 35px;
}

.settings_icon {
  box-shadow: none !important;
  margin-right: 0px !important;
  width: 50px !important;
}

.settings_icon svg {
  width: 40px !important;
  height: 40px !important;
}

.video_bg img {
  border-radius: 30px;
}

.video_header span {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 0px 3px 6px #0000003b;
  margin-right: 15px;
  cursor: pointer;
}

.video_header span svg {
  width: 20px;
  height: 20px;
}

.video_header .video_icon {
  background: #67dd9c;
}

.video_header .sound_icon {
  background: #005182;
}

.video_header_timer {
  background: #d7effe;
  padding: 8px 14px;
  border-radius: 10px;
}

.video_call_status {
  background: white;
  width: 30%;
  margin: 0 auto;
}

.video_header_timer p {
  border-bottom: 1px solid #f2f2f2;
}

.video_header_timer svg {
  width: 24px;
  height: 24px;
}

.video_call_status p {
  border-bottom: 1px solid #f2f2f2;
  width: 100%;
  padding-bottom: 15px;
}

.video_call_status span {
  align-self: flex-start;
}

.video_call_status span svg {
  width: 25px;
  height: 25px;
}

.video_call_status p:last-child {
  border-bottom: none !important;
}

.ratings_box .card {
  border-color: #f8f9fa;
  border-radius: 20px;
  background: white;
}

.ratings_box .card-header {
  background: #f0f9ff;
  border-radius: 20px;
  margin: 10px;
  border-bottom: none !important;
}

.face_ratings span {
  margin-right: 10px;
  cursor: pointer;
}

.ratings_box .card-footer button {
  color: #fff;
  background: #ff554b;
  border-radius: 4px;
  padding: 8px 50px;
}

.ratings_box .card-footer {
  background: white;
  border-color: white;
  border-radius: 20px;
}

.radio_box2 p {
  font-size: 15px;
}

.ratings_box textarea {
  background: #f5fbff;
  border: 1px solid #009ff4;
  border-radius: 16px;
  color: #005182 !important;
  font-size: 16px;
  padding: 12px 20px;
}

.ratings_box .card-footer .skip_btn,
.skip_btn:hover {
  background: #e9f7ff;
  border-color: #e9f7ff;
}

.favorite_btn,
.favorite_btn:hover {
  border-color: #ff554b;
  color: #ff554b;
}

.ratings_last_btns button {
  width: 40%;
}

.ratings_section {
  height: 100vh;
  display: flex;
  align-items: center;
}

.leave_call {
  border-radius: 30px;
}

.leave_call .modal-header {
  background: #fff !important;
}

.teacher_video,
.student_video {
  background: white;
  padding: 20px;
  border-radius: 20px;
}

.teacher_video img,
.student_video img {
  border-radius: 15px;
}

.bg_white {
  background: white;
  padding: 20px;
  border-radius: 20px;
}
.video_call_size {
  height: 300px;
  width: 25vw;
}
.chat_ib h5 {
  font-size: 15px;
  color: #464646;
  margin: 0 0 8px 0;
}

.chat_ib h5 span {
  font-size: 13px;
  float: right;
}

.chat_ib p {
  font-size: 14px;
  color: #989898;
  margin: auto;
}

.chat_img {
  float: left;
  width: 11%;
}

.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people {
  overflow: hidden;
  clear: both;
}

.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}

.inbox_chat {
  height: 550px;
  overflow-y: scroll;
}

.active_chat {
  background: #ebebeb;
}

.incoming_msg_img {
  display: inline-block;
  width: 30px;
  height: 30px;
}

.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  max-width: 82%;
}

.received_withd_msg p {
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
  background: #f2f3f5;
  border-radius: 0px 8px 8px 8px;
}

.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}

.received_withd_msg {
  max-width: 100%;
}

.mesgs {
  float: left;
  padding: 30px 15px 0 25px;
  width: 100%;
  background: white;
  border-radius: 20px;
}

.sent_msg p {
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
  background: rgb(10, 158, 248);
  background: linear-gradient(
    143deg,
    rgba(10, 158, 248, 1) 0%,
    rgba(11, 152, 238, 1) 21%,
    rgba(16, 130, 198, 1) 100%
  );
  border-radius: 8px 8px 0px 8px;
}

.outgoing_msg {
  overflow: hidden;
}
.cursorNotAllow {
  cursor: not-allowed;
}
.sent_msg {
  float: right;
  max-width: 90%;
  padding-right: 15px;
}

.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.type_msg {
  position: relative;
}

.msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}

.messaging {
  padding: 0 0 50px 0;
}
.overFlow {
  max-height: 400px;
  overflow-y: scroll;
}

.msg_history {
  // height:50px;
  overflow-y: auto;
}

.incoming_msg_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.input_msg_write .write_msg {
  background: #e0f3fe !important;
  border-color: #e0f3fe !important;
  border-radius: 8px !important;
}

.send_msg_btn,
.make_note {
  background: #0a9ef8;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.make_note {
  background: #f66962;
}

.send_msg_btn svg,
.make_note svg {
  width: 18px;
  height: 18px;
}

.msg_action_btn {
  display: flex;
  justify-content: flex-end;
  position: absolute !important;
  top: 6px;
  right: 10px;
}
.tableColor {
  background: #005182;
}
.transalte_accordion .accordion-button {
  background: #e0f3fe;
  border-color: #e0f3fe !important;
  border-radius: 8px;
}

.transalte_accordion .accordion-item {
  border: none !important;
}

.transalte_accordion .form-select,
.transalte_accordion input {
  background-color: #e0f3fe !important;
  border-color: #e0f3fe !important;
  color: #005182 !important;
}

.google_text {
  font-size: 10px;
}

.lesson_ended_blue {
  height: 100%;
  background: #0a9ef8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  flex-direction: column;
}

.lesson_ended_blue h3 {
  font-size: 36px;
  font-family: 'KumbhSans-Bold';
}

.plans_box {
  background: white;
  padding: 30px 0;
  border-radius: 30px;
  box-shadow: 0px -1px 20px #00000014;
}

.plans_box select {
  background-color: #ebf8ff;
  border-color: #bbe4fd !important;
  width: 100%;
  height: 60px;
  color: #005182 !important;
  font-family: 'KumbhSans-Bold';
  font-size: 18px;
}

.plans_box option {
  font-size: 16px !important;
  font-family: KumbhSans-Regular !important;
}

.terms_policies_box {
  background: #eef9ff;
  text-align: center;
  padding: 60px;
}

.terms_policies_box h5 {
  color: #009ff4;
  font-size: 30px;
  font-family: 'KumbhSans-Bold';
}

.pt_6 {
  padding-top: 66px;
}

.terms_text p {
  color: #2c6384;
}

.d_none {
  display: none;
}
.terms_text h5 {
  color: #067fbf;
  font-size: 18px;
}

.contact_text h5 {
  color: #067fbf;
  font-size: 18px;
}

.contact_text p {
  color: #6e9ebc;
}

.text_border_bottom {
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 15px;
}

.contact_text h6 {
  color: #067fbf;
  font-size: 18px;
  font-family: 'KumbhSans-Bold';
}

.contact_form {
  background: #fbfcfc;
  padding: 30px 30px 100px 30px;
}

.contact_form label {
  color: #067fbf;
  font-family: 'KumbhSans-Bold';
}

.contact_form input {
  color: #067fbf;
  font-family: 'KumbhSans-Bold';
}

.contact_form input,
.contact_form textarea {
  background: white;
  border-color: #e8e8e8;
  border-radius: 0px !important;
  padding: 12px !important;
}

.support_accordian .accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #fff;
  box-shadow: 0px 0px 6px #009ff34a !important;
  border: none !important;
  border-radius: 0px !important;
}

.support_accordian .accordion-item {
  background-color: #fff;
  border: none !important;
}

.support_accordian .accordion-button {
  color: #2c6384 !important;
  font-family: 'KumbhSans-Bold';
  box-shadow: 0px 0px 6px #009ff34a !important;
  margin-bottom: 15px;
}

.support_accordian .accordion-body {
  color: #85939b;
  font-family: 'KumbhSans-Regular';
  background-color: #fff;
  box-shadow: 0px 0px 6px #009ff34a !important;
  border: none !important;
  border-radius: 0px !important;
  margin-bottom: 20px;
}

.search_bar_icon {
  position: absolute;
  left: 18px;
  top: 12px;
}

form.search_input_bar {
  width: 60%;
  margin: 0 auto;
}

.search_input_bar input {
  border-radius: 50px !important;
  border-color: white !important;
  padding: 12px 45px !important;
}

.faq_text h5 {
  color: #009ff4;
  font-family: 'KumbhSans-Bold';
}

.faq_text p {
  color: #2c6384;
}

.inbox {
  background: #fbfcfc;
  padding: 20px 0;
}

.sender_img {
  width: 50px;
  height: 50px;
}

.sender_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}

.message_date {
  position: absolute;
  right: 0;
  top: 0;
  color: #0a9ef8;
}

span.message_date {
  position: absolute;
  right: 20px;
  top: -2px;
  color: #0a9ef8;
}

.chat_header {
  background: #f7fcff;
  padding: 20px 30px;
}

.tutor_active:before {
  content: '';
  display: inline-block;
  background: #67dd9c;
  width: 10px;
  height: 10px;
  border-radius: 40px;
  margin-right: 4px;
}

.message_chatbox .mesgs {
  float: left;
  padding: 30px 15px 0 25px;
  width: 100%;
  background: #f8f9fa;
  border-radius: 20px;
}

.message_chatbox .received_withd_msg p {
  color: #ffffff;
  font-size: 16px;
  margin: 0;
  padding: 8px 15px 8px 18px;
  width: 100%;
  background: #0b99f0;
  border-radius: 0px 8px 8px 8px;
  box-shadow: 0px 0px 3px #00000021;
}

.message_chatbox .sent_msg p {
  color: #005182;
  font-size: 16px;
  margin: 0;
  padding: 8px 15px 8px 18px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 3px #00000021;
  border-radius: 8px 8px 0px 8px;
}

.message_chatbox .msg_history {
  height: 400px;
}

.message_chatbox .outgoing_msg {
  display: flex;
  justify-content: flex-end;
}

.commint_level {
  display: flex;
  justify-content: space-around;
  background: red;
  background-color: #ebf8ff;
  border-color: #bbe4fd !important;
  color: #005182 !important;
  border-radius: 8px;
  align-items: center;
  font-size: 18px;
}

.commint_level label {
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.commint_level .form-check {
  flex-grow: 1;
  height: 60px;
  display: flex;
  align-items: center;
  text-align: center;
}

.commint_level .form-check-input:checked + .form-check-label {
  font-family: KumbhSans-Bold;
  transition: all 0.4s;
  background: #009ff4;
  border-radius: 8px !important;
  color: white !important;
}

.plan_action_btn button,
.plan_action_btn a {
  padding: 16px 10px !important;
  width: 48% !important;
}

span.next_btn svg {
  width: 25px;
  height: 25px;
  margin-left: 8px;
}

h5.about_heading {
  color: #f66962;
  background: #feedec;
  padding: 10px;
  width: max-content;
  border-radius: 3px;
  font-size: 18px;
}

.about_img img {
  border-radius: 20px;
}

.service_first {
  background: #fff5bf;
  padding: 20px;
  text-align: center;
  border-radius: 20px;
}

.service_third {
  background: #dde3ff;
  padding: 20px;
  text-align: center;
  border-radius: 20px;
}

.service_third {
  background: #bff5ff;
  padding: 20px;
  text-align: center;
  border-radius: 20px;
}

.service_fourth {
  background: #e3ffea;
  padding: 20px;
  text-align: center;
  border-radius: 20px;
}

.about_stat span svg {
  width: 100px;
  height: 100px;
  margin-bottom: 30px;
}

.bg_light_blue {
  background-color: #f4fbff;
}

.about_stat p {
  color: #6e9ebc;
}

.team_card {
  background: white;
  border-radius: 20px;
  box-shadow: 0px 0px 16px #0000002b;
  margin: 20px;
  display: flex;
}

.team_img img {
  border-radius: 20px 0px 0px 20px;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.team_info p {
  color: #85939b;
}

.owl-carousel2 .owl-dots .owl-dot span,
.blog_slider .owl-dots .owl-dot span {
  width: 18px !important;
  height: 10px !important;
  background-color: #ffd2d0 !important;
}

.owl-carousel2 .owl-dots .owl-dot.active span,
.blog_slider .owl-dots .owl-dot.active span {
  background-color: #f66962 !important;
  width: 30px !important;
  height: 10px !important;
}

.blog_img {
  height: 200px;
  width: 100%;
  position: relative;
}

.blog_img img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.blog_card {
  background: #f4fbff;
  border-radius: 10px;
}

.blog_info {
  display: flex;
  justify-content: space-between;
}

.blog_img span {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: #b5b5b5;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
}

.broker_form input,
.broker_form textarea {
  background-color: #fafdff !important;
  border-color: #eff8fe !important;
  padding: 12px 15px !important;
}

.broker_form input::placeholder {
  font-size: 14px !important;
  color: #a6b0b7;
}

.broker_form input:-ms-input-placeholder {
  font-size: 14px !important;
  color: #a6b0b7;
}

.broker_form input::-ms-input-placeholder {
  font-size: 14px !important;
  color: #a6b0b7;
}

.broker_submit_btn,
.broker_submit_btn:hover {
  background: #ff554b;
  color: white;
  border-color: #ff554b;
  padding: 12px 10px;
  box-shadow: 0px 4px 15px #98070026;
  font-size: 18px;
  border-radius: 4px;
  width: 25%;
}

.broker_image {
  width: 100%;
  height: 250px;
}
.school_image {
  width: 100%;
  height: 150px;
}

.broker_image img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  border-radius: 20px;
}
.school_image img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  border-radius: 20px;
}

.broker_heading {
  padding: 25px;
  text-align: center;
  background-color: #f2faff;
  border-radius: 20px 20px 0 0;
}

.broker_setting_card {
  background: white;
  box-shadow: 0px 0px 20px #0000000a;
  border-radius: 20px;
}

.broker_form select {
  width: 100%;
  height: 50px;
  background-color: #fafdff !important;
  border-color: #eff8fe !important;
}

.broker_form .radio_box {
  min-width: 90px !important;
  padding: 8px !important;
}

.link_input_group button,
.link_input_group button:hover {
  margin: 4px;
  background: #ebf8ff;
  border-color: #ebf8ff;
  border-radius: 10px !important;
  color: #009ff4;
  font-family: 'KumbhSans-Bold';
  font-size: 14px;
}

.link_input_group {
  background-color: #fafdff !important;
  border-color: #eff8fe !important;
  border: 1px solid #eff8fe !important;
  border-radius: 8px !important;
}

.link_input_group input {
  border: none !important;
}

.broker_btn button,
.broker_btn a {
  width: 150px;
}

.progress_stat_card {
  background: white;
  border-radius: 30px;
  box-shadow: 0px -1px 16px #00000012;
  margin: -80px auto;
  width: 100%;
  z-index: 9;
  margin-bottom: 40px;
}

.stat_box {
  background: #edf8ff;
  padding: 20px;
  text-align: center;
  width: 25%;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stat_card .stat_box:first-child {
  border-radius: 20px 0px 0 20px;
}

.stat_card .stat_box:last-child {
  border-radius: 0px 20px 20px 0px;
  margin: 0px !important;
}

.stat_box p {
  margin: 0px;
}
.bg_skyblue {
  background: #0a9ef8 !important;
  border-color: #0a9ef8 !important;
}
.bg_blue,
.bg_blue:hover {
  background: #005182 !important;
  border-color: #005182 !important;
  color: white !important;
}

.bg_lt_blue,
.bg_lt_blue:hover {
  background: #009ff4 !important;
  border-color: #009ff4 !important;
  color: white !important;
}
.gColor {
  background-color: #d4ebf7 !important;
}
.boxColor {
  text-align: center;
  background: #009ef8 !important;
  color: #ffff;
  border-radius: 10px;
  font-size: 14px;
}
.bg_light_green,
.bg_light_green:hover {
  background: #67dd9c !important;
  border-color: #67dd9c !important;
  color: white !important;
}

.bg_light_red,
.bg_light_red:hover {
  background: #f66962 !important;
  border-color: #f66962 !important;
  color: white !important;
}

.user_img {
  width: 60px;
  height: 50px;
}

.user_img img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  border-radius: 6px;
}

tr {
  vertical-align: middle;
}

.lesson_btn,
.lesson_btn:hover {
  background: #6e9ebc;
  border-color: #6e9ebc;
  border-radius: 0px;
}

.video_btn,
.video_btn:hover {
  background: #f66962;
  border-color: #f66962;
  border-radius: 0px;
}

.session_history_table {
  background: white;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  margin-bottom: 15px;
  margin-right: 20px;
}
.session_history_overFlow {
  height: 550px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.session_history_table tr {
  background: #f9fbfc;
  border-bottom: 5px solid white;
}

.session_history_table tr:last-child {
  border-bottom: none !important;
}

.session_history_table .table > :not(caption) > * > * {
  border: none !important;
}

.progress_graph {
  background: #f5fbff;
  border-radius: 30px;
  display: flex;
  justify-content: center;
}

.session_history_table .dropdown-toggle::after {
  display: none !important;
}

.session_history_table .dropdown-menu.show {
  background: white;
  border: white;
  border-radius: 10px;
  box-shadow: 0px 2px 6px #0000001c;
}

.session_history_table a.dropdown-item {
  border-bottom: 1px solid #edf3f6;
  color: #005182;
}

a.dropdown-item {
  border-bottom: 1px solid #edf3f6;
  color: #005182;
}

.course_card {
  background: #3191c1;
  border-radius: 12px;
  height: 200px;
}

.course_img {
  position: absolute;
  top: 5px;
  right: 20px;
}

.course_info p {
  line-height: 20px;
}

.progress.progress_bar_edit {
  height: 5px;
}

.progress-bar {
  background: #67dd9c;
}

.course_card2 {
  background: #437a53;
  border-radius: 12px;
  height: 200px;
}

.course_card3 {
  background: #8790fb;
  border-radius: 12px;
  height: 200px;
}

.course_card4 {
  background: #ffb5b6;
  border-radius: 12px;
  height: 200px;
}

.course_card5 {
  background: #ffc754;
  border-radius: 12px;
  height: 210px;
}

.course_card4 .progress-bar,
.course_card5 .progress-bar {
  background: #ff847e;
}

.complete_icon svg {
  width: 16px;
  height: 16px;
}

.course_card_curve:before,
.course_card_curve2:before,
.course_card_curve3:before,
.course_card_curve4:before,
.course_card_curve5:before,
.course_card_curve6:before,
.course_card_curve7:before,
.course_card_curve8:before,
.course_card_curve9:before,
.course_card_curve10:before,
.course_card_curve11:before,
.course_card_curve12:before {
  content: '';
  position: absolute;
  background-image: url('../../images/course_curve.png');
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  z-index: -9;
}

.course_card_curve {
  background: #ffc754;
  border-radius: 12px;
  height: 250px;
  z-index: 99;
}

.course_card_curve2 {
  background: #8790fb;
  border-radius: 12px;
  height: 200px;
  z-index: 99;
}

.course_card_curve3 {
  background: #437a53;
  border-radius: 12px;
  height: 200px;
  z-index: 99;
}

.course_card_curve4 {
  background: #ffb5b6;
  border-radius: 12px;
  height: 200px;
  z-index: 99;
}

.course_card_curve5 {
  background: #0c6a9a;
  border-radius: 12px;
  height: 200px;
  z-index: 99;
}

.course_card_curve6 {
  background: #ea3d67;
  border-radius: 12px;
  height: 200px;
  z-index: 99;
}

.course_card_curve7 {
  background: #e06c00;
  border-radius: 12px;
  height: 200px;
  z-index: 99;
}

.course_card_curve8 {
  background: #7046c3;
  border-radius: 12px;
  height: 200px;
  z-index: 99;
}

.course_card_curve9 {
  background: #3b70d5;
  border-radius: 12px;
  height: 200px;
  z-index: 99;
}

.course_card_curve10 {
  background: #f47458;
  border-radius: 12px;
  height: 200px;
  z-index: 99;
}

.course_card_curve11 {
  background: #ffb5b6;
  border-radius: 12px;
  height: 200px;
  z-index: 99;
}

.course_card_curve12 {
  background: #405a9b;
  border-radius: 12px;
  height: 200px;
  z-index: 99;
}

.syllabus_list li {
  border-bottom: 1px solid #0051821c;
  padding-bottom: 8px;
  margin: 8px;
  color: #2c6384;
}

.syllabus_list li:last-child {
  border-bottom: none !important;
}

.course_shapes img {
  width: auto !important;
}

.shopping_modal,
.cancel_lesson_modal {
  border-radius: 20px;
}

.shopping_habit_card {
  background: #f4f9fc;
  padding: 10px 20px;
  border-radius: 20px;
}

.student_record_text {
  background: #f8fcff;
  padding: 20px;
  border: 1px solid #c2dfef;
  border-radius: 20px;
  width: 50%;
  margin: 0 auto;
}

.student_record_info {
  border-bottom: 1px solid #dff1fe;
  color: #005192;
  font-size: 18px;
  display: flex;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.student_record_info:last-child {
  margin-bottom: 0px;
  border-bottom: none !important;
  padding-bottom: 0px;
}

.attendance_record .table th {
  background: #f5f5f5 !important;
  color: #005182;
  font-family: 'KumbhSans-Bold';
  padding: 12px 20px;
}

.attendance_record .table {
  border-color: #ebebeb;
}

.attendance_record td {
  color: #005182;
  padding: 12px 20px;
}

.student_border_bottom {
  border-bottom: 1px solid #dbe6ed;
  padding-bottom: 5px;
}

.schedule_lession_cancel {
  background: #f4f7fa;
  padding: 30px;
  border-radius: 30px 30px 0 0;
  text-align: center;
}

.border_radius_none {
  border-radius: 0px !important;
}

.schedule_lession_details .form-control {
  background: #f5f5f5;
  border-color: #f5f5f5;
  border-radius: 12px;
}

.schedule_lession_details button {
  width: 30%;
}

.p-8 {
  padding: 5rem !important;
}

.cancel_lesson_modal textarea {
  background: #eff9ff;
  border-color: #cbe6f5;
  border-radius: 20px;
}

.whts_app_btn,
.whts_app_btn:hover {
  position: fixed;
  z-index: 999;
  bottom: 80px;
  right: 30px;
  background: white;
  border-radius: 50px;
  padding: 10px 15px;
  border-color: white;
  box-shadow: 0px 0px 6px #009ff440;
  color: #001726;
  animation: pulse-red 1.5s infinite;
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgba(40, 181, 114, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(40, 181, 114, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(40, 181, 114, 0);
  }
}

.message_popup_box span {
  position: absolute;
  right: 0px;
  top: 1px;
  color: #0a9ef8;
  background: none;
  border-radius: 0px;
  width: max-content;
  margin: 0;
  height: max-content;
  font-size: 13px;
}

.mark_read_box {
  background: #e5f5ff;
  padding: 10px;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
}

.inbox_popup.show {
  height: 32vh;
}

.message_popup_box p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 200px;
}

.inbox_popup {
  height: 300px;
  overflow-y: auto;
}

.inbox_popup::-webkit-scrollbar {
  width: 4px;
}

.inbox_popup::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.inbox_popup::-webkit-scrollbar-thumb {
  background: #dbdbdb;
}

.inbox_popup::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}

.referral_box .card-body {
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px -1px 20px #0000001a;
}

.referral_box .card {
  border: none;
  border-radius: 20px;
}

.referral_box {
  width: 900px;
  margin: 0 auto;
}

.free_mins_text {
  color: #ec62f6;
}

.register_text {
  color: #6293f6;
}

.invitation_text {
  color: #50bfff;
}

.invite_group input,
.invite_group input:focus {
  border: none !important;
  padding: 0px !important;
  background: transparent !important;
}

.invite_group {
  border: 1px solid #e1f2ff !important;
  border-radius: 50px !important;
  padding: 5px 10px 5px 20px !important;
  color: #005182 !important;
}

.invite_group button,
.invite_group button:hover {
  border-radius: 50px !important;
  background: #f66962;
  color: white;
  border-color: #f66962;
  padding: 5px 5px 5px 20px;
}

.share_link_box {
  background: #f5fbff;
  border: 1px solid #d5efff;
  border-radius: 60px;
  padding: 14px 20px;
}

.no_invites_found {
  background: #fffaf9;
  border: 5px solid white;
  border-radius: 30px;
  padding: 15px;
  /* box-shadow: 0 0 1px #ff0c00; */
}

.no_invite_outline {
  border: 1px solid #f66962;
  width: 60%;
  margin: 0 auto;
  border-radius: 30px;
  padding: 5px;
}

.plan_price_estimate,
.plan_price_estimate:hover {
  border: 1px solid #bbe4fd;
  background: white !important;
  border-radius: 10px;
  color: #005182;
  font-size: 18px;
}

.discount_text {
  background: #fef0ef;
  padding: 8px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #f66962;
  margin-left: 10px;
  position: relative;
  top: -2px;
}

.commint_level .form-check-input:checked .discount_text + .form-check-label .discount_text {
  background: red !important;
}

.home_input_group input {
  border-radius: 0px !important;
  border: none !important;
}

.home_input_group a,
.home_input_group a:hover {
  background: #ff554b;
  color: white;
  border-color: #ff554b;
  padding: 10px 20px;
  border-radius: 0px !important;
}

.home_input_group {
  box-shadow: 0px 1px 20px #0000000f;
}

.d-flex.align-items-center.mb-3.calendar_popup_box {
  background: #c9ebfd;
}

.calendar_header {
  background: #c9ebfd;
  padding: 10px 14px;
}

.calendar_header span {
  width: max-content;
  margin-right: 5px;
  height: max-content;
  background: transparent;
}

.calendar_date {
  background: white;
  height: 70px;
  width: 70px;
  border-radius: 20px;
  box-shadow: 0px 0px 15px #0000002b;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 10px;
}

.calendar_date span {
  width: max-content;
  margin-right: 0px;
  height: max-content;
  background: transparent;
}

.student_lesson {
  background: #f7f7f7;
  padding: 10px;
  border-radius: 15px;
  width: 75%;
}

.student_lesson_img {
  width: 45px;
  height: 45px;
}

.student_lesson_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
}

.student_lesson span {
  background: #f8f8f8;
  width: max-content;
  margin-right: 0px;
  height: max-content;
  background: transparent;
}

.calendar_body span {
  width: max-content;
  margin-right: 0px;
  height: max-content;
  background: transparent;
  display: inline-flex;
}

.calendar_body a {
  width: 75% !important;
  padding: 10px !important;
  margin: 40px auto 0 !important;
  display: block !important;
}

.form-check .form-check-input {
  // height: 32px;
}

.form-check-input:checked {
  background-color: #67dd9c;
}

.custom_dropdown::-webkit-scrollbar {
  width: 4px;
}

.custom_dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom_dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #dbdbdb;
}

.custom_dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}

.dropdown.nn {
  z-index: auto;
}

.vocabulary .mesgs {
  height: 86vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.vocabulary_box {
  background: #fff;
  height: 100%;
  border-radius: 20px;
}

.vocabulary .mesgs .table {
  background: #fff;
  border-radius: 20px;
}

.vocabulary_box a {
  background: #005182;
  padding: 12px 12px;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  margin: 3px 11px;
}

.vocabulary .mesgs .table th {
  border-right: 1px solid #dedede;
  font-size: 14px;
}

.vocabulary .mesgs .table tr:last-child {
  border-bottom: transparent;
}

.full_width_img {
  border-radius: 15px;
  width: 100%;
  height: 39vh;
  object-fit: cover;
}

.dropdown.nn {
  perspective: none;
}

.emoji_set {
  position: absolute;
  right: 20px;
  top: 15px;
}

.attendance_record.single_certificate .certificate_img {
  border-radius: 12px;
  text-align: center;
  width: 29%;
}

.modal-footer .btn {
  width: 30%;
}

/* .ratings_active1 {display: none;} 


 .ratings_active1.svg2 {display: inline-block;} */

.face_ratings span.active:before {
  position: absolute;
  content: '';
  top: 1px;
  left: 1px;
  right: 0;
  bottom: 0px;
  background-color: red;
  z-index: 0;
  height: 32px;
  width: 32px;
  border-radius: 100%;
}

.face_ratings span svg {
  position: relative;
}

.face_ratings span.active svg path,
.face_ratings span.active svg circle {
  fill: #fff;
}

.face_ratings span {
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  display: inline-block !important;
}

/* ================Become a Ambassador css start=============== */

.ambassador_banner {
  background: url('../../images/ambassador_banner.jpg') no-repeat center top;
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.ambassador_banner .bannerText {
  background-color: rgba(21, 18, 18, 0.44);
  max-width: 680px;
  padding: 2.3rem;
}

// .bannerText::before {
//   content: '';
//   position: absolute;
//   top: 0;
//   width: 100%;
//   left: 0;
//   background: url('../../images/border_half.svg') no-repeat left top;
//   height: 100%;
//   background-size: contain;
// }

.rounded-5 {
  border-radius: 15px;
}

.ambassador_service {
  padding: 80px 0;
}

.ambassador_service .card:after {
  position: absolute;
  content: '';
  right: -15px;
  height: 65px;
  border-right: 5px solid #eff9ff !important;
  border-radius: 5px !important;
  top: 50%;
  bottom: 0;
}

.remove_border_right.card:after {
  right: 0;
  height: 0px;
  border-right: 0px solid #eff9ff !important;
}

/* =================================== */

/* .img_bg{ background: url('../../images/audience_bg.jpg') no-repeat center top; background-size: cover; height: 530px;} */

.ambassador_service_full.fullBg1,
.ambassador_service_full.fullBg2 {
  background-size: 50% auto;
  height: 530px;
}

.fullBg1 {
  background: url('../../images/audience_bg.jpg') no-repeat right top;
}

.fullBg2 {
  background: url('../../images/good_fit_bg.jpg') no-repeat left top;
}

.flat_card {
  background-color: #f5fcff;
  box-shadow: -15px 10px 0px 0px #eff9ff;
}

.check_bullet li {
  position: relative;
  padding-left: 1.8rem;
}

.check_bullet li:not(:last-child) {
  margin-bottom: 0.8rem;
}

.check_bullet li::before {
  position: absolute;
  content: '';
  left: 0;
  background: url('../../images/li_tick.svg') no-repeat left top;
  width: 18px;
  height: 18px;
}

.fullBg1 .flat_card {
  margin-left: 5rem;
}

.fullBg2 .flat_card {
  margin-right: 5rem;
}

.topAmbassador {
  padding: 80px 0;
}

.ambassador_social li:not(:last-child) {
  margin-right: 10px;
}

.ambassador_social li a {
  width: 24px;
  height: 24px;
  background-color: #f4f4f4;
  display: inline-flex;
  padding: 6px;
  border-radius: 3px;
}

.media-left img {
  border-radius: 10px;
}

.ambassador_slider .item > .ambassador_column:last-child {
  margin-top: 3.5rem;
}

.center_heading {
  max-width: 600px;
}

/*new css*/

section.ambassador_service.get_started {
  background-color: #f2faff;
}

.get_started .card-body {
  background-color: #f2faff;
}

.get_started .card:after {
  position: absolute;
  right: -25px;
  height: 65px;
  content: url('../../images/arrow_red.png');
  top: 50%;
  bottom: 0;
}

.remove_border_right:after {
  display: none;
}

.get_btn {
  width: 220px;
}

section.ambassador_service.get_started {
  padding: 60px 0;
}

.card.text-center.border-0.position-relative.teacher_dash::after {
  display: none;
}

.teacher_dash_card {
  background-color: #f2faff;
}

.teacher_dash .card-body {
  background: rgba(242, 250, 255, 0.53);
  -webkit-box-shadow: inset 0 0 8px #d7dee3;
  box-shadow: inset 0 0 8px #d7dee3;
}

.img_new {
  padding: 20px;
  background-color: #f2faff;
  -webkit-box-shadow: inset 0 0 8px #d7dee3;
  box-shadow: inset 0 0 8px #d7dee3;
}

.inner-card {
  padding: 16px 16px 35px;
}

.carousel-item::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  opacity: 0.6;
  z-index: 1;
}

header.welcome_header_bg.teacher-dash {
  background: #f9fdff;
  height: 32px;
}

.carousel-caption.d-none.d-md-block {
  position: absolute;
  right: 15%;
  bottom: 40px;
  left: 120px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: left;
  top: 130px;
  max-width: 648px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none;
}

.carousel-indicators-new {
  position: absolute;
  bottom: 60px;
  right: 98px;
  z-index: 1;
}

section.inner-carousel {
  position: relative;
  top: 50px;
}

.carousel-indicators-new button.active {
  background: #f66962;
  width: 37px;
  height: 37px;
  border: 0;
  color: #ffffff;
  font-weight: 600;
  font-size: 15px;
}

.carousel-indicators-new button {
  background: #c7c7c6;
  width: 37px;
  height: 37px;
  border: 0;
  color: #ffffff;
  font-weight: 600;
  font-size: 15px;
}

/* mahesh css */

#practice_popup .modal-content {
  width: 71%;
  margin: 0 auto;
}

.logout_text span.tutor_text {
  width: auto;
  color: #000;
}

.parent > li > ul > li {
  background: orange;
}

.btn:hover {
  color: none;
}

.header_after_login span.mgs {
  background-color: #0c8ada1c;
}

.hours span {
  font-size: 26px;
  color: #0fd4ca;
}

.shadow_btn {
  box-shadow: 2px 3px 0px 0px #4bc582;
  background-color: #67dd9c;
}

.msg_shadow_btn {
  background-color: #04e5e5;
  border: none;
  color: #fff;
  box-shadow: 2px 3px 0px 0px #12b9b9;
}

.msg_shadow_btn:hover {
  background-color: #04e5e5;
  border: none;
  color: #fff;
  box-shadow: 2px 3px 0px 0px #12b9b9;
}

#practice_popup .carousel-item::after {
  display: none;
}

#practice_popup .carousel-indicators {
  margin-left: 3%;
}

#practice_popup .carousel-indicators [data-bs-target] {
  background-color: #f52e24;
  height: 4px;
  width: 24px;
  border-radius: 10px;
}

#practice_popup .carousel-item .practice_img {
  padding: 16px 0;
  margin-left: 23px;
  min-height: 237px;
  display: flex;
  align-items: center;
}

.chinese_btn,
.chinese_btn:hover,
.chinese_btn:active,
.chinese_btn:visited,
.chinese_btn:focus {
  background: white;
  border-color: white;
}

.dropdown.drop_profile .dropdown-menu.show {
  width: auto;
  padding-bottom: 0;
}

.filters_cards .dropdown-menu.show li {
  padding: 14px 18px;
  border-bottom: 1px solid #f3f3f3;
}

.filters_cards .dropdown-menu.show li a:hover {
  padding: 0;
  color: #000;
}

.filters_cards .dropdown-menu.show li a {
  padding: 0px;
  display: flex;
}

.filters_cards .dropdown-menu.show li img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

#practice_popup .carousel-item .favorite_btn.btn-outline-secondary,
#practice_popup .carousel-item .favorite_btn.btn-outline-secondar:hover,
#practice_popup .carousel-item .favorite_btn.btn-outline-secondary :visited,
#practice_popup .carousel-item .favorite_btn.btn-outline-secondary :focus {
  background: white;
}

.dropdown.drop_profile .dropdown-menu.show a.dropdown-item {
  border-bottom: none;
}

.whts_app.supports_fix .supports_fix_btn {
  left: 15px;
  background: #cbbd44;
  color: #fff;
  width: 150px;
  animation: none;
}

#practice_popup .carousel-item .practice_btn {
  margin: 0px 10px;
  padding-bottom: 12px;
}

.progress_timing ul {
  display: flex;
  background: #edf8ff;
  margin-top: -24px;
  padding: 14px;
  height: 54px;
  border-radius: 1px 1px 30px 30px;
}

.color_info {
  color: #0dcaf0 !important;
}

.terms_text.site_map h5 {
  margin-top: 28px;
}

.terms_text ul li {
  color: #2c6384;
  margin-top: 12px;
}

ul.site_map_info {
  font-size: 18px;
  border-left: 1px solid #ededed;
}

.header_after_login span:first-child {
  background-color: #0c8ada1c;
}

.progress_graph {
  height: 350px;
}

.progress_timing ul li {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.progress_timing ul li::before {
  content: '\2022';
  margin-right: 3px;
  font-size: 59px;
  padding-top: 9px;
  color: #0a9ef8;
}

.progress_timing ul li:first-child::before {
  color: #67dd9c;
}

.progress_timing ul li:last-child::before {
  color: #f66962;
}

.login_we_chat {
  background-color: #03db6c !important;
}

ul.site_map_info li {
  list-style-type: none;
  color: #0a9ef8;
  font-size: 15px;
  cursor: pointer;
  margin-top: 18px;
}

/* end of mahesh css */

/* ========================================================
===================Website Survay css======================
======================================================== */

.survay-sec .survay-content {
  max-width: 730px;
}

.survay-sec .survay-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 24px;
}

.survay-sec .survay-card .form-control {
  color: #2c6384 !important;
  font-size: 16px;
  padding: 11px 18px !important;
  border: none !important;
  background-color: #f7fcff;
  border-radius: 7px !important;
  font-family: KumbhSans-Regular;
  max-width: 85%;
  margin: auto;
}

.survay-sec .survay-card .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #2c6384;
  opacity: 1;
  /* Firefox */
}

.survay-sec .survay-card .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #2c6384;
}

.survay-sec .survay-card .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #2c6384;
}

.survay-sec .survay-card .content-txt {
  padding: 0 3rem;
}

.radio-btns-cstm .radio-itm {
  position: relative;
  width: fit-content;
  margin-right: 15px;
  margin-bottom: 15px;
}

.radio-btns-cstm .radio-itm:last-child {
  margin-right: 0;
}

.radio-btns-cstm label {
  font-size: 16px;
  color: #2c6384;
  padding: 11px 25px;
  background-color: #f0f9ff;
  box-shadow: 0 3px 6px #def3ff inset;
  border-radius: 7px;
}

.radio-btns-cstm.rating-radio label {
  height: 47px;
  width: 47px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-btns-cstm input {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.radio-btns-cstm input:checked + label {
  background-color: #f66962;
  color: #ffffff;
  font-family: KumbhSans-Bold !important;
  box-shadow: 3px 3px 0 #de3a32;
}

.radio-btns-cstm.rating-radio .radio-itm:nth-child(1) input:checked + label {
  background-color: #ea3431;
  box-shadow: 3px 3px 0 #c41714;
}

.radio-btns-cstm.rating-radio .radio-itm:nth-child(2) input:checked + label {
  background-color: #f74445;
  box-shadow: 3px 3px 0 #d11e1f;
}

.radio-btns-cstm.rating-radio .radio-itm:nth-child(3) input:checked + label {
  background-color: #ff6443;
  box-shadow: 3px 3px 0 #df5031;
}

.radio-btns-cstm.rating-radio .radio-itm:nth-child(4) input:checked + label {
  background-color: #ff8538;
  box-shadow: 3px 3px 0 #db651b;
}

.radio-btns-cstm.rating-radio .radio-itm:nth-child(5) input:checked + label {
  background-color: #ffa62a;
  box-shadow: 3px 3px 0 #d88005;
}

.radio-btns-cstm.rating-radio .radio-itm:nth-child(6) input:checked + label {
  background-color: #ffc000;
  box-shadow: 3px 3px 0 #c99300;
}

.radio-btns-cstm.rating-radio .radio-itm:nth-child(7) input:checked + label {
  background-color: #e3c400;
  box-shadow: 3px 3px 0 #c6ab00;
}

.radio-btns-cstm.rating-radio .radio-itm:nth-child(8) input:checked + label {
  background-color: #bfc800;
  box-shadow: 3px 3px 0 #9ca401;
}

.radio-btns-cstm.rating-radio .radio-itm:nth-child(9) input:checked + label {
  background-color: #9dcb00;
  box-shadow: 3px 3px 0 #8cb20a;
}

.radio-btns-cstm.rating-radio .radio-itm:nth-child(10) input:checked + label {
  background-color: #79ca00;
  box-shadow: 3px 3px 0 #5e9c00;
}

.radio-btns-cstm.rating-radio .radio-itm:nth-child(11) input:checked + label {
  background-color: #30ae00;
  box-shadow: 3px 3px 0 #2a9a00;
}

.survay-sec .btn-red-outline {
  color: #f66962;
  border: 1px solid #f66962;
  border-radius: 7px;
  justify-content: center;
  width: 28%;
  background-color: transparent;
}

.survay-sec .btn-red-outline:hover {
  color: #fff;
  background-color: #f66962;
}

.survay-sec .btn-red-outline svg {
  margin-bottom: 2px;
}

.survay-sec .btn-red-outline:hover svg path {
  fill: #fff;
}

.survay-sec .btn-red,
.thankyoumodal .btn-red {
  color: #ffffff;
  border-color: #f66962;
  border-radius: 7px;
  background-color: #f66962;
  padding: 7px 52px 6px 52px;
}

.survay-sec .btn-red:hover,
.thankyoumodal .btn-red:hover {
  box-shadow: 3px 3px 0 #de3a32;
}

.survay-sec .backbtn {
  color: #009ff4;
}

.survay-sec .backbtn svg {
  margin-bottom: 2px;
}

/* =====================================================
========================kid interface css===============
===================================================== */

/* =================Create account================== */

.stepper-card {
  border-radius: 10px;
  background-color: #fff;
  padding: 25px;
  box-shadow: inset 0 1px 10px rgba(255, 88, 80, 0.29);
}

.progressbar .bar-item {
  color: #85939b;
  padding-top: 90px;
  font-family: KumbhSans-Regular;
  position: relative;
}

.progressbar .bar-item.active {
  color: #f66962;
  font-family: KumbhSans-Bold;
}

.progressbar .bar-item:first-child {
  padding-top: 0;
}

.progressbar .bar-item::after {
  content: '';
  width: 2px;
  height: 100%;
  background-color: #ffeeed;
  position: absolute;
  left: 17px;
  z-index: 0;
  top: 0;
}

.progressbar .bar-item.active::after {
  background-color: #f66962;
}

.progressbar .bar-item .status {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: #ffeeed;
  border: 1px solid #ffeeed;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  margin-top: -3px;
  z-index: 1;
}

.progressbar .bar-item.active .status {
  border-color: #f66962;
  background-color: #fff;
}

.progressbar .bar-item.active .status img {
  display: block;
}

.progressbar .bar-item img {
  display: none;
}

.cmnbox {
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(6, 127, 191, 0.9);
  padding: 25px;
}

.kid-create-acc label {
  color: #005182;
  font-size: 16px;
  font-family: 'KumbhSans-Bold';
  margin-bottom: 10px;
}

.kid-create-acc .form-control:focus,
.kid-create-acc .form-select:focus {
  border-color: #0a9ef8 !important;
}

.kid-create-acc .upload-sec {
  height: 200px;
  width: 200px;
}

.kid-create-acc .upload-sec .img-wrap {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff5f5;
}

.kid-create-acc .uploadbtn {
  position: absolute;
  top: 0;
  right: 0;
}

.kid-create-acc .uploadbtn .btn-inner {
  height: 60px;
  width: 60px;
  position: relative;
  overflow: #ffe5e3;
  background-color: #ffe5e3;
  border-radius: 50%;
}

.kid-create-acc .uploadbtn input {
  position: absolute;
  height: px;
  width: 60px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.kid-create-acc .nextbtn {
  width: 290px;
  height: 43px;
  margin-top: 35px;
}

.image-radio {
  position: relative;
}

.image-radio .content-sec {
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  padding: 7px;
  border: 2px solid #fff;
}

.image-radio .content-sec .img-wrap {
  border-radius: 16px;
  overflow: hidden;
  position: relative;
}

.image-radio input {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}

.image-radio input:checked + .content-sec {
  border-color: #f66962;
}

.image-radio .text-sec {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  z-index: 1;
  min-height: 113px;
}

.image-radio .text-sec h2 {
  margin-bottom: 5px;
}

.image-radio .text-sec p {
  line-height: 19px;
}

.image-radio .content-sec .img-wrap::after {
  content: '';
  position: absolute;
  top: 00;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(rgba(177, 160, 160, 0.13), rgba(8, 0, 0, 1));
  z-index: 0;
}

/* ======Select account page css======= */

.select_account .img-wrap {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: #fff5f5;
}

.select_account .btn-white {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px 25px;
  cursor: pointer;
}

.select_account .btn-white span {
  margin-bottom: -2px;
}

.select_account .cmnbox .edit {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #ffe5e3;
  border-bottom-left-radius: 20px;
  justify-content: center;
  height: 45px;
  width: 45px;
  display: block;
  text-align: center;
  line-height: 45px;
}

.select_account .cmnbox .edit img {
  width: 20px;
}

.select_account .cmnbox {
  overflow: hidden;
}

/* ========Select account end======== */

/* ================Edit Kid Account css============= */

.kid-create-acc .subs-sec {
  background-color: #f5fff9;
  border: 1px solid #eafaf1;
  border-radius: 10px;
  padding: 15px 30px;
}

.kid-create-acc .btns-sec .btn {
  height: 43px;
  width: 185px;
}

.kid-create-acc .delete-btn {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #ffffff;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: #f66962;
  width: 185px;
  height: 43px;
}

.border_30 {
  border-radius: 30px !important;
}

/* =========End======== */

/* =======change kid image start====== */

.choose-img-row .choose-itm {
  margin: 10px;
}

.choose-img-row .choose-itm .img-wrap {
  height: 123px;
  width: 123px;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgba(255, 98, 98, 0.5);
  box-shadow: inset 0 3px 6px rgba(255, 49, 39, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.choose-img-row .choose-itm .checkimg {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

.choose-img-row .choose-itm input {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.choose-img-row .choose-itm input:checked + .checkimg {
  display: block;
}

.choose-img-row .choose-itm input:checked + .checkimg + .img-wrap {
  background-color: rgba(103, 221, 156, 0.15);
  box-shadow: inset 0 3px 6px rgba(7, 72, 37, 0.15);
}

.modal-footer.cstm-btns .btn {
  height: 43px;
  width: 185px;
}

/* ====End==== */

/* ==========kids terms start========= */

.kids-terms .form-check {
  width: fit-content;
  padding: 9px 12px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
}

.kids-terms .form-check .form-check-input {
  height: 24px;
  width: 24px;
  padding: 0 !important;
  border-radius: 4px !important;
  margin-left: 0;
  margin-right: 13px;
}

.kids-terms .form-check .form-check-label {
  line-height: 1;
  margin-top: 2px !important;
}

.calendar_display_check .form-check-input:checked {
  background-color: #f66962;
  border-color: #f66962 !important;
}

/* ==========End========= */

/* =======kid home start======== */

.kid-home .enrollnow {
  background-color: #fff;
  height: 40px;
  width: 150px;
  justify-content: space-between;
  border-radius: 45px;
}

.kid-home .filters_cards {
  margin-bottom: 30px;
}

.kid-home .brower-content {
  width: 204px;
  background-color: #fff;
  padding: 20px 25px;
  border-radius: 50px;
}
.form-select {
  background-image: none;
}
.padding {
  padding-left: 40px !important;
  width: 310px !important;
  height: 40px !important;
}
.kid-teachers .head-sec .form-select {
  height: 38px;
  border-radius: 38px !important;
  background-color: #fff;
  border: none !important;
  width: fit-content;
  padding-right: 40px !important;
}

select.orange-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('../../images/orange-arrow.png');
  background-position: right 12px center;
  background-repeat: no-repeat;
  background-size: 15px;
}

.kid-teachers .teacher-itm {
  box-shadow: 0 0 16px rgba(246, 105, 98, 0.22);
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 30px;
}

.teacher-itm .img-wrap img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.kid-teachers .teacher-itm .img-wrap {
  position: relative;
}

.kid-teachers .teacher-itm .img-wrap .status {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #f66962;
  border-radius: 50px;
  padding: 9px 18px 8px 18px;
  line-height: 1;
}

.kid-teachers .teacher-itm .img-wrap .status.beginning {
  background-color: #8888ff;
}

.kid-teachers .teacher-itm .img-wrap .status.level {
  background-color: #1d1d1d;
}

.kid-teachers .teacher-itm h2 {
  padding: 13px 13px 0 13px;
  padding-top: 10px;
}

.kid-teachers .teacher-itm p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0 13px 0px 13px;
  margin-bottom: 13px;
}

.kids_test_tabs .nav-link img {
  width: 140px;
  height: 160px;
  object-fit: cover;
  border-radius: 10px;
}

.kids_test_tabs .nav-link {
  display: block;
  padding: 0px;
  border: 3px solid #fff;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.kids_test_tabs .nav-link.active,
.kids_test_tabs .show > .nav-link {
  color: #fff;
  background-color: transparent;
  border: 3px solid #f66962;
}

.tutor_category_box {
  background: #fff2f1;
  padding: 30px;
  border-radius: 20px;
}

.tutor_subject {
  background: #ff7c76;
  padding: 12px 20px;
  font-size: 16px;
  color: white;
  border-radius: 30px;
  font-family: 'KumbhSans-Regular';
  position: relative;
  top: -5px;
  left: 10px;
}

.chatbox_tabs {
  background: #ebf8ff;
  display: flex;
  justify-content: space-between;
}

.chatbox_tabs .nav-link.active,
.chatbox_tabs .show > .nav-link {
  color: #fff;
  background-color: #f66962;
  border-radius: 0px;
  padding: 10px 46px;
  text-align: center;
}

.chatbox_tabs .nav-link {
  color: #005182;
}

span.tutor_country {
  width: 70px;
  height: 70px;
  background: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 6px #f6696247;
  border-radius: 50px;
  position: absolute;
  top: -30px;
  right: 40px;
}

/* ===========Kid detail page css============ */

.kid-detail .video_wrap {
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
  aspect-ratio: 1.3/1;
}

.kid-detail .video_wrap video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.kid-detail .accordion-item {
  border: none !important;
  background: none !important;
  padding: 0 10px;
}

.kid-detail .accordion-button:not(.collapsed) {
  background-color: none !important;
  box-shadow: none !important;
}

.kid-detail .accordion-button {
  background-color: transparent !important;
  padding: 20px 0 10px 0;
}

.kid-detail .accordion-body {
  padding: 0;
  margin-bottom: 20px;
}

.kid-detail .accordion-button::after {
  margin-top: -1px;
}

.kid-detail .accordion-body .user_box {
  padding: 9px 11px;
  background-color: #fff1f1;
  border-radius: 10px;
}

.kid-detail .accordion-body .img-wrap {
  height: 55px;
  width: 55px;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #f66962;
  margin-right: 10px;
}

.kid-detail .accordion-body .img-wrap img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* ==========Stepper css include here========= */

.kid-detail .stepper-sec {
  background-color: #fffbfb;
  border-radius: 30px;
  padding: 40px;
  flex: 1;
}

/*form styles*/

.kid-detail #msform {
  text-align: center;
  position: relative;
}

.kid-detail #msform fieldset {
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

/*Hide all except first fieldset*/

.kid-detail #msform fieldset:not(:first-of-type) {
  display: none;
}

/*progressbar*/

.kid-detail #progressbar {
  margin-bottom: 35px;
  overflow: hidden;
  counter-reset: step;
  display: flex;
  align-items: center;
  justify-content: center;
}

.kid-detail #progressbar li {
  list-style-type: none;
  padding: 0 10px;
}

.kid-detail #progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 40px;
  height: 40px;
  line-height: 45px;
  display: block;
  font-size: 12px;
  color: #333;
  background: white;
  border-radius: 10px;
  margin: 0 auto 10px auto;
  font-size: 20px;
  font-family: KumbhSans-Regular;
  background-color: #f7f7f7;
  color: #b1b1b1;
}

.kid-detail #progressbar li.active:before {
  font-family: KumbhSans-Bold;
  background-color: #f66962;
  color: #fff;
}

.kid-detail .fs_title {
  margin-bottom: 30px;
}

.kid-detail .answer-itm {
  position: relative;
  margin-bottom: 20px;
}

.kid-detail .answer-itm input {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.kid-detail .answer-itm input:checked + label {
  color: #28b572;
  border-color: #28b572;
}

.kid-detail .answer-itm label {
  border: 1px solid #ffeaea;
  border-radius: 16px;
  background-color: #fff;
  width: 100%;
  font-family: KumbhSans-Regular;
  font-size: 18px;
  height: 58px;
  text-align: left;
  line-height: 58px;
  padding-left: 24px;
  color: #005182;
}

.next.action-button {
  background: none !important;
  border: none !important;
}

/* Teacher css starts */

.teacher_balance_box {
  padding: 20px;
  background: #f6fefa;
  border-radius: 25px;
}
.teacher_current_balance {
  background: white;
  padding: 14px;
  border: 1px solid #b1eecf;
  border-radius: 20px;
}
.teacher_profile_accordion .accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #fff;
  box-shadow: none;
  margin-bottom: 15px;
  background: #f9fdff;
}
.teacher_profile_accordion .accordion-item {
  background-color: #fff;
  border: none !important;
}
.teacher_profile_accordion .accordion-button {
  color: #2c6384 !important;
  font-family: 'KumbhSans-Bold';
  box-shadow: none;
  margin-bottom: 15px;
  background: #f9fdff;
}
.teacher_profile_accrodion .accordion-button:first-child {
  border-radius: 20px;
}
.teacher_profile_accordion th,
.teacher_profile_accordion td {
  color: #005182;
  font-size: 14px;
}
.teacher_profile_accordion .accordion-button::after {
  flex-shrink: 0;
  width: 10px;
  height: 20px;
  margin-left: auto;
  content: '';
  background-image: url('../../images/accordian-right-arrow.svg') no-repeat;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  background-size: contain;
}
.teacher_profile_accordion .accordion-button:not(.collapsed)::after {
  transform: rotate(90deg);
}
.balance_line {
  width: 70%;
  height: 4px;
  background: #e0f7ed;
}
.notifications_stngs label {
  margin-left: 10px;
  margin-top: 8px;
}
.teacher_video_intro {
  width: 100%;
  height: 250px;
}
.teacher_video_intro img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.teacher_video_intro button,
.teacher_video_intro button:hover {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #f52e24;
  color: white;
  border-color: #f52e24;
  border-radius: 12px;
}
.teacher_balance_box2 {
  padding: 20px;
  background: #f4fbff;
  border-radius: 25px;
}
.teacher_upload_btn {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.teacher_upload_btn .invite_btn {
  background: #2c6384;
  color: white;
  border-color: #2c6384;
  border-radius: 8px;
  padding: 8px 20px;
  box-shadow: none;
}
.teacher_upload_btn .record_btn {
  background: #67dd9c;
  color: white;
  border-color: #67dd9c;
  border-radius: 8px;
  padding: 8px 20px;
  box-shadow: none;
}
.red_theme_btn {
  min-width: 120px;
}
.teacher_info {
  background: #fff4f4;
  padding: 15px;
  border-radius: 10px;
}
.create_profile {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #feeeee;
  height: 200px;
  border-radius: 22px;
  position: relative;
  overflow: hidden;
  .show_image {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hidden_input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
  }
}
.create_profilee {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #feeeee;
  height: 100px;
  border-radius: 22px;
  position: relative;
  overflow: hidden;
  .show_image {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hidden_input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
  }
}

.teacher_info_form .form-select {
  // background-image: url('../../images/down-arrow-red.svg');
}
.language_added:before {
  width: 30px;
  background-image: url('../../images/delete_lang.svg');
  content: '';
  position: absolute;
  right: -30px;
  height: 30px;
  background-repeat: no-repeat;
  top: 7px;
  cursor: pointer;
}
.teacher_resume_uploader {
  width: 100%;
  height: 70px;
  background: #e6f6fe;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #2c6384;
  border-radius: 5px;
  position: relative;
  .resume_hidden {
    position: absolute;
    opacity: 0;
    top: 0;
    bottom: 0;
    width: 100%;
  }
}
.add_courses {
  background: #e5f9ee;
  height: 150px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.teacher_video_uploader {
  width: 100%;
  height: 70px;
  background: #fee8e7;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #f66962;
  border-radius: 5px;
  position: relative;
  .hide_video {
    position: absolute;
    opacity: 0;
    top: 0;
    bottom: 0;
    width: 100%;
  }
}
.blue_cancel_btn,
.blue_cancel_btn:hover {
  background: #e1ebf1;
  border-color: #e1ebf1;
  padding: 8px 20px;
  border-radius: 4px;
  color: #005182;
}
.green_btn,
.green_btn:hover {
  background: #67dd9c;
  border-color: #67dd9c;
  color: white;
  padding: 8px 40px;
  border-radius: 4px;
  font-size: 18px;
}
.who_teach_box {
  background: #fff6f6;
  padding: 10px;
  border-radius: 6px;
}
.edit_red_btn {
  background: #fff7f6;
  border-color: #fff7f6;
  padding: 8px 20px;
  border-radius: 4px;
  box-shadow: none;
  font-size: 18px;
}
.teacher_stu_img {
  width: 100%;
  height: 250px;
}
.teacher_stu_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.blue_btn,
.blue_btn:hover {
  background: #009ff4;
  border-color: #009ff4;
  color: white;
  padding: 8px 40px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.ban_btn,
.ban_btn:hover {
  background: #f52e24;
  border-color: #f52e24;
  color: white;
  padding: 8px 40px;
  border-radius: 4px;
  font-size: 18px;
}
.teacher_action_btn .btn {
  padding: 10px 14px;
  border-radius: 10px;
  font-size: 14px;
  width: 31%;
}
.teacher_action_btn svg {
  position: relative;
  top: -2px;
  width: 16px;
  height: 16px;
}
.teacher_action_btn svg {
  position: relative;
  top: -2px;
  width: 16px;
  height: 16px;
}
.student_info_box {
  background: white;
  padding: 20px;
  box-shadow: 0px 1px 10px #009ff438;
  border-radius: 10px;
}
.mints_box {
  background: #e6fff1;
  padding: 10px 20px;
  border-radius: 10px;
}
.mints_box p {
  color: #30db7d;
}
.custom_student_tabs .nav-link {
  color: #2c6384;
  font-size: 16px;
  border: none !important;
  position: relative;
}
.custom_student_tabs .nav-link.active {
  color: #f66962;
  border: none;
  font-family: 'KumbhSans-Bold';
  background: #f5fcff;
}
.custom_student_tabs .nav-link:hover,
.custom_student_tabs .nav-link:focus {
  border: none;
}
.custom_student_tabs .nav-tabs {
  border-bottom: none !important;
  background: #f5fcff;
  padding: 10px;
  border-radius: 14px;
}
.custom_student_tabs .nav-link.active:after {
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  background: #f66962;
  border-radius: 20px;
  left: 50%;
  top: auto;
  transform: translate(-50%, -50%);
  bottom: -8px;
}
.no_msg_box p {
  content: '';
  position: absolute;
  border-radius: 20px;
  left: 50%;
  top: auto;
  transform: translate(-50%, -50%);
  bottom: 10px;
}
.notes_custom_tabs .nav-tabs {
  background: none;
  border-bottom: 2px solid #707070 !important;
  border-radius: 0px;
  padding: 0px;
}
.notes_custom_tabs .nav-link.active {
  color: #f66962;
  border: none;
  font-family: 'KumbhSans-Bold';
  background: #fff;
  border-bottom: 2px solid #f66962 !important;
}
.notes_custom_tabs .nav-link.active:after {
  display: none;
}
.notes_counts {
  background: #f4f4f4;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 9px;
  color: #005182;
  font-family: 'KumbhSans-Bold';
  margin-right: 10px;
}
.notes_custom_tabs .nav-link {
  padding: 14px 20px;
}
.notes_custom_tabs .nav-link.active .notes_counts {
  background: #feeeee;
  color: #f66962;
}
span.notes_category {
  background: #67dd9c;
  padding: 5px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 10px;
  margin-right: 10px;
  font-family: 'KumbhSans-Bold';
  font-size: 12px;
}
.notes_card_box {
  background: #fbfbfb;
  padding: 20px;
  border-radius: 20px;
}
.notes_popup_box {
  box-shadow: 0px 3px 6px #00000014;
  border: none;
  border-radius: 15px;
  left: auto !important;
  right: 0 !important;
  top: 10px !important;
}
.notes_popup_box p {
  padding: 5px 15px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 12px;
}
.notes_popup_box p:last-child {
  border-bottom: none !important;
}
.notes_popup_box p span {
  margin-right: 5px;
}
.new_notes {
  background: #f4fdf8;
}
.new_notes .note_title,
.new_notes .note_title:hover {
  color: #67dd9c !important;
}
.notes_category_color {
  background: #67ddd8 !important;
}
.notes_category_color2 {
  background: #6790dd !important;
}
.notes_category_color3 {
  background: #ddb067 !important;
}
.student_details_box {
  background: white;
  border: 1px solid #eaeaea;
  padding: 15px;
  border-radius: 8px;
}
.mistake_card {
  background: #fff2f1;
  padding: 20px;
  border-radius: 10px;
}
.mistake_line:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 70px;
  top: 14px;
  background: #e5a29e;
  right: 0;
  border-radius: 10px;
}
.cancel_lesson_modal .modal-header,
.cancel_lesson_modal .modal-footer {
  border: none !important;
  border-color: white;
}
.solve_question_box {
  background: white;
  border: 1px solid #cbc4c4;
  padding: 6px;
  border-radius: 10px;
}
.solve_question_box textarea {
  background: #eff9ff;
  border-radius: 20px;
  border: none;
}
.teacher_resrc_card {
  background: #e3f5ff;
  padding: 20px;
  border-radius: 30px;
}
.resrc_img {
  width: 90px;
  height: 90px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
}
.resrc_img img {
  width: 50px;
}
.w_80 {
  width: 80%;
}
.border-none {
  border: none;
}
.saved_links {
  width: 300px;
  background: #fef0ef;
  padding: 40px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  flex-direction: column;
}
.edit_basic_box {
  background: #fff7f785;
  padding: 30px;
  border-radius: 20px;
}
.theme_red_btn,
.theme_red_btn:hover {
  background: white;
  color: #0a9ef8;
  border-color: #ff554b;
  padding: 8px 20px;
  border-radius: 12px;
  box-shadow: none;
  font-size: 18px;
}
.review_card_white {
  padding: 20px;
  border-radius: 25px;
}
.review_card_white .review_ratings span {
  background: #ffffff;
  border-radius: 6px;
  width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f7f6f2;
}
.review_card_white .review_ratings:after {
  content: '';
  font-size: 70px;
  position: absolute;
  right: 0px;
  top: 15px;
  width: 30%;
  background: #ffe5e5;
  height: 2px;
}
.review_card_white .review_ratings:before {
  content: '';
  font-size: 70px;
  position: absolute;
  left: 0px;
  top: 15px;
  width: 30%;
  background: #ffe5e5;
  height: 2px;
}
.review_card_white:before {
  content: '';
  font-size: 70px;
  position: absolute;
  right: 40px;
  top: 30px;
  width: 40px;
  background-image: url('../../images/testimonial_sign.svg');
  height: 40px;
  background-size: contain;
  z-index: 999;
  background-repeat: no-repeat;
}
.checked_btn {
  padding: 8px 20px;
  border: 1px solid red;
  border-radius: 60px;
  display: inline-block;
}
.search_input_box input {
  background: white;
  border-radius: 40px !important;
  border: 1px solid #e0e0e0 !important;
}
.search_input_box {
  width: 250px;
}
.search_input_box input::placeholder {
  color: #b2b2b2;
}
.search_input_box input:-ms-input-placeholder {
  color: #b2b2b2;
}
.search_input_box input::-ms-input-placeholder {
  color: #b2b2b2;
}
.search_icon {
  width: 32px;
  height: 32px;
  background: #feeeed;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  position: absolute;
  top: 5px;
  right: 7px;
}
.resources_list li {
  background: #f2faff;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #d8f1ff;
  position: relative;
  margin-bottom: 20px;
}
.resources_list li:last-child {
  margin-bottom: 0px;
}
.resources_list li a:after {
  content: '';
  position: absolute;
  background: url('../../images/list_arrow.svg');
  width: 25px;
  height: 25px;
  top: 22px;
  background-repeat: no-repeat;
  right: 10px;
}
.red_light_btn,
.red_light_btn:hover {
  background: #feeeed;
  border-color: #feeeed;
  padding: 8px 30px;
  border-radius: 8px;
}
.library_tabs .active {
  background: #f66962 !important;
  border-bottom: none !important;
}
.library_tabs .nav-link {
  color: #2c6384;
  border-radius: 0px !important;
  padding: 10px 20px;
  text-align: left;
  border-bottom: 1px solid #fff4f4;
}
.library_tabs .nav-link:last-child {
  border-bottom: none !important;
}
.pl_30 {
  padding-left: 30px;
}
.credit_table tbody,
.credit_table tbody tr,
.credit_table tbody td,
.credit_table tbody th {
  background-color: #f4fbff !important;
}
.credit_table thead,
.credit_table thead tr,
.credit_table thead th {
  background-color: #009ff4 !important;
}
.credit_table thead th {
  padding: 12px 20px;
  color: white;
}
.credit_table tbody th,
.credit_table tbody td {
  padding: 15px 20px;
  color: #005182;
}
.credit_table thead th:first-child {
  border-radius: 10px 0 0 10px;
}
.credit_table thead th:last-child {
  border-radius: 0 10px 10px 0;
}
.credit_table tbody td,
.credit_table tbody th {
  border-color: #def3ff;
}
.credit_table tbody tr:first-child th:first-child {
  border-radius: 12px 0 0 0 !important;
}
.credit_table tbody tr:first-child td:last-child {
  border-radius: 0 12px 0 0 !important;
}
.credit_table tbody tr:last-child th:first-child {
  border-radius: 0 0 0 12px !important;
}
.credit_table tbody tr:last-child td:last-child {
  border-radius: 0 0 12px 0 !important;
}
.credit_table tbody tr:last-child td,
.credit_table tbody tr:last-child th {
  border: none !important;
}
.credit_table tbody:before {
  line-height: 1.5em;
  content: '.';
  background: white;
  color: white;
  display: block;
}
.cancel_reasons .form-check .form-check-input {
  height: 25px;
  width: 25px !important;
  display: block;
  padding: 0 !important;
  border-radius: 40px !important;
  border-color: #e6caca !important;
}
.cancel_reasons .form-check label {
  margin: 5px 12px 10px;
}
.modal-body {
  padding: 1.5rem 1rem;
}
.stat_card2 .stat_box:last-child {
  margin: 0px !important;
}
.stat_card2 .stat_box {
  border-radius: 0px;
  background: #ffffff;
  padding: 20px;
  text-align: center;
  width: 20%;
  margin-right: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.stat_card2 {
  background: #dbedff;
  box-shadow: 0px 1px 6px #e8f2fd;
}
.stat_color h4,
.stat_color p,
.stat_color h4:hover,
.stat_color p:hover {
  color: #67dd9c !important;
}
.stat_color2 h4,
.stat_color2 p,
.stat_color2 h4:hover,
.stat_color2 p:hover {
  color: #fabc00 !important;
}
.stat_color3 h4,
.stat_color3 p,
.stat_color3 h4:hover,
.stat_color3 p:hover {
  color: #009fff !important;
}
.stat_color4 h4,
.stat_color4 p,
.stat_color4 h4:hover,
.stat_color4 p:hover {
  color: #d963c6 !important;
}
.stat_color5 h4,
.stat_color5 p,
.stat_color5 h4:hover,
.stat_color5 p:hover,
.stat_color5 small {
  color: #00b6e2 !important;
}
.stat_color6 h4,
.stat_color6 p,
.stat_color6 h4:hover,
.stat_color6 p:hover {
  color: #f66962 !important;
}
.current_ratings span svg {
  width: 16px;
  height: 16px;
  margin-right: 3px;
}
.current_ratings .inactive svg {
  opacity: 0.4;
}
.regular_heading {
  box-shadow: 0px 0px 10px #e7e7e7;
  border-radius: 20px 20px 0 0;
}
.call_search input {
  border-radius: 10px !important;
  padding: 12px 45px !important;
  border-color: #e0e0e0 !important;
}
.download_history_btn {
  background: #67dd9c;
  width: 46px;
  height: 46px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}
.call_history {
  border: 1px solid #d5d5d5;
  border-radius: 20px;
  padding: 20px;
  height: auto !important;
}
.p_50 {
  padding: 50px;
}
.video_call_status .circle_inactive {
  width: 25px;
  height: 25px;
  background: #f5fbff !important;
  border-radius: 50px;
}
.progress_box {
  background: #f5f7f8;
  padding: 30px;
  border-radius: 20px;
}
.progress_box .progress {
  display: flex;
  height: 8px;
  overflow: hidden;
  background-color: #efefef;
  border-radius: 40px;
}
.bar_blue {
  background: #009ff4;
}
.red_blue {
  background: #f66962;
}
.chat_ready_bg {
  // background: #edf9ff;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}
.opacity_light_btn {
  opacity: 0.2;
}
.volume_icon {
  // background: #f7f7f7 !important;
  width: 50px;
  height: 50px;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px !important;
}
.timezone_dropdown {
  background: white;
  width: 346px;
  box-shadow: 0px 3px 6px #e9e9e9;
  border-radius: 20px 0;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
}
.timezone_dropdown .down_arrow {
  position: absolute;
  position: absolute;
  top: -3px;
  right: 2px;
}
.teacher_nav_tabs {
  width: max-content;
  border: 1px solid #f66962;
  border-radius: 50px;
  margin: 0 auto;
}
.teacher_nav_tabs .nav-link {
  border: none !important;
  border-radius: 80px;
  color: #f66962;
  padding: 12px 40px;
}
.teacher_nav_tabs .nav-link.active,
.teacher_nav_tabs .nav-item.show .nav-link {
  background: #f66962;
  border-color: #f66962;
  color: white;
}
.turn_on_ring_box {
  background: #fff9f8;
  padding: 30px;
  border-radius: 30px;
  text-align: center;
}
.visible_box {
  background: #f7fcff;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
}
.add_member_input {
  border: 1px solid #009ff4;
  padding: 6px;
  border-radius: 10px;
}
.add_member_input button {
  background: #67dd9c;
  border-color: #67dd9c;
  border-radius: 6px !important;
  color: white;
  padding: 8px 35px;
}
.add_member_input input {
  border: none !important;
  padding-left: 0px;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: auto !important;
}
.add_member_input input::placeholder {
  color: #009ff4;
}
.add_member_input input:-ms-input-placeholder {
  color: #009ff4;
}
.add_member_input input::-ms-input-placeholder {
  color: #009ff4;
}
.upcoming_event img {
  width: 30px;
  height: 30px;
}
.upcoming_event_tab .accordion-button .collapsed {
  border: 1px solid #e0e0e0 !important;
  border-radius: 20px !important;
}
.upcoming_event_tab .accordion-item {
  border: none !important;
}
.upcoming_event_tab .accordion-button {
  // background: none !important;
  border-radius: 20px !important;
  box-shadow: none !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 10px !important;
}
.incoming_calls_box {
  background: #faf4f4;
  padding: 30px;
  border-radius: 20px;
}
.btn-color {
  background-color: #f66962;
  color: white;
  font-weight: 600;
  padding: 10px 30px;
}
.btn-color:hover {
  background-color: #f66962;
  color: white;
  font-weight: 600;
}
.incoming_calls_box .accordion-item {
  border: none !important;
  background: none !important;
}
.incoming_calls_box .accordion-button:not(.collapsed) {
  background-color: none !important;
  box-shadow: none !important;
}
.incoming_calls_box .accordion-button {
  background-color: transparent !important;
  padding: 20px 0 10px 0;
}
.call_info_bg span {
  background: white;
  padding: 10px 20px;
  display: inline-block;
  border-radius: 10px;
  margin-right: 15px;
}
.call_info_bg span svg {
  width: 18px;
  height: 18px;
}
.incoming_calls_box .accordion-body {
  padding: 1rem 1.25rem;
  padding: 0px 70px;
  margin-bottom: 20px;
}
.input_msg_write input {
  background: transparent;
  border: none !important;
  border-radius: 0px !important;
  border-top: 1px solid #d4e1e9 !important;
}
.msg_action_btn svg {
  width: 16px;
  height: 16px;
}
.sent_msg.sent_img {
  width: 50%;
}
.sent_msg.sent_img img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 30px;
}
.sent_msg.sent_img span {
  background: white;
  width: 46px;
  height: 46px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  position: absolute;
  bottom: 10px;
  right: 30px;
  outline: 5px solid #ffffff5e;
}
.sent_msg.sent_img p {
  background: none;
  border: none !important;
  box-shadow: none !important;
  position: absolute;
  bottom: 0;
  color: white;
  font-size: 22px;
  font-family: 'KumbhSans-Bold';
  text-align: center;
  width: 70%;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 10px;
}
.inbox_message {
  height: calc(100vh - 260px);
  overflow-y: auto;
}
.date_picker_custom input[type='date']::-webkit-calendar-picker-indicator {
  background-image: url('../../images/blue_date_calendar.svg');
}
.cancel_reservation_box .dropdown button {
  background: #fff8f8;
  width: 100%;
  padding: 10px;
  border: 1px solid #fdd2d1;
  border-radius: 10px;
  text-align: left;
}
.cancel_reservation_box .dropdown-item {
  color: #303030;
  padding: 10px 20px !important;
  border-bottom: 1px solid #e5e5e5;
}
.cancel_reservation_box .dropdown-menu.show {
  display: block;
  width: 100%;
  box-shadow: 0px 0px 10px #00000038;
  border-radius: 14px;
  border-color: white !important;
  top: 10px !important;
}
.faq_welcome_bg {
  background: #f0f9ff;
}
.search_input_bar2 input {
  border-radius: 50px !important;
  border-color: #f1f1f1 !important;
  padding: 12px 45px !important;
}
.border_left_gray {
  border-left: 1px solid #eeeeee;
  padding-left: 40px;
}
.yes_btn,
.yes_btn:hover {
  color: #67dd9c;
  border: 2px solid #67dd9c;
  border-radius: 60px;
  padding: 6px 20px;
  background: white !important;
}
.no_btn,
.no_btn:hover {
  color: #f52e24;
  border: 2px solid #f52e24;
  border-radius: 60px;
  padding: 6px 20px;
  background: white !important;
}
.article_box {
  width: max-content;
  margin: 0 auto;
  text-align: center;
}
.faq_social_links span {
  border: 1px solid #f1f1f1;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-right: 10px;
}
.ratings_section_center {
  height: calc(100vh - 70px);
  display: flex;
  align-items: center;
}
.google_calendar_btn,
.google_calendar_btn:hover {
  background: #ceedfd;
  border-color: #ceedfd;
  padding: 10px 20px;
  color: #009ff4;
  font-size: 18px;
  font-family: 'KumbhSans-Bold';
}
.tutor_messages_tabs {
  background: white;
  padding: 15px 30px;
  border-radius: 20px;
  justify-content: space-around;
}

.tutor_messages_tabs .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
  color: #576974;
  padding: 10px 25px;
}

.tutor_messages_tabs .nav-link.active,
.tutor_messages_tabs .show > .nav-link {
  color: #fff;
  background-color: #f66962;
  border-radius: 12px;
  font-family: 'KumbhSans-Bold';
}

.student_history_box .accordion-button {
  background-color: transparent !important;
  padding: 20px 0 10px 0;
  box-shadow: none !important;
}

.student_history_box .accordion-button::after {
  //   background-image: url('../../images/down-arrow-red.svg');
  background: url('../../images/down-arrow-red.svg');
}

.student_history_box .accordion-item {
  background-color: white;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 3px 12px #bdbdbd24;
  border: none !important;
}

.notes_textarea textarea {
  background-color: #e0f3fe !important;
  border-color: #e0f3fe !important;
  color: #005182 !important;
}

.notes_textarea button {
  flex-grow: 1;
}

.add_material_dropdown .dropdown button {
  background: white;
  width: 100%;
  padding: 10px;
  border: 1px solid #6eb3d8;
  border-radius: 10px;
  text-align: left;
}
.add_material_dropdown .dropdown-item {
  color: #303030;
  padding: 10px 20px !important;
  border-bottom: 1px solid #e5e5e5;
}
.add_material_dropdown .dropdown-menu.show {
  display: block;
  width: 100%;
  box-shadow: 0px 0px 10px #00000038;
  border-radius: 14px;
  border-color: white !important;
  top: 10px !important;
}

.add_material_dropdown .dropdown-toggle::after {
  border: none !important;
  position: absolute;
  right: 10px;
  top: 22px;
  background: url('../../images/blue_arrow_down.svg');
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.respond_dropdown .dropdown-item {
  color: #303030;
  padding: 10px 20px !important;
  border-bottom: none !important;
}

.respond_dropdown li {
  padding: 0px !important;
  border-bottom: 1px solid #e5e5e5;
}

.respond_dropdown .dropdown-menu.show {
  display: block;
  width: 100%;
  box-shadow: 0px 0px 10px #00000038;
  border-radius: 14px;
  border-color: white !important;
  top: 10px !important;
}

.filters_cards2 {
  background: white;
  padding: 30px 0;
  border-radius: 30px;
  box-shadow: 0px -1px 16px #00000012;
  margin: -80px auto;
  width: 100%;
  z-index: 9;
  margin-bottom: 40px;
}
.respond_dropdown button,
.respond_dropdown button:hover {
  background: #f66962;
  border-color: #f66962;
  border-radius: 14px;
  padding: 8px 18px;
}

.respond_dropdown li:last-child {
  border-bottom: none !important;
}

.calendar_display_check .form-check {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.reservation_work_box {
  background: #eff9ff;
}

.reservation_radus {
  border-radius: 33px 23px 0px 0px;
}
.calendar_display_check .form-check-input:checked[type='checkbox'] {
  background-image: url('../../images/checked_white.svg');
  background-size: contain;
  background-size: 60%;
}

.timer_input input {
  background: #f5f2f2 !important;
  border-color: #f5f2f2 !important;
  width: 50px !important;
  height: 50px !important;
  text-align: center;
}

.timer_input input::placeholder {
  color: #005182 !important;
  font-family: KumbhSans-Bold !important;
}

.timer_input input:-ms-input-placeholder {
  color: #005182 !important;
  font-family: KumbhSans-Bold !important;
}

.timer_input input::-ms-input-placeholder {
  color: #005182 !important;
  font-family: KumbhSans-Bold !important;
}

/* .add_material_dropdown .dropdown-item:last-child{
    border-bottom: none !important;
} */

.respond_dropdown {
  perspective: inherit;
  z-index: auto;
}

.login_img {
  height: 50vh;
}

.login_img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.timezone_dropdown .dropdown-item {
  color: #303030;
  padding: 10px 20px !important;
  border-bottom: none !important;
}

.timezone_dropdown li {
  padding: 0px !important;
  border-bottom: 1px solid #e5e5e5;
}

.timezone_dropdown .dropdown-menu.show {
  width: max-content !important;
  top: 20px !important;
  left: auto !important;
  right: 30px !important;
}
/* Teacher css ends */

/* School Landing page Start  */

.school_banner {
  background: url('../../images/school_single_bannner.jpg') no-repeat center bottom;
  height: 80vh;
  background-size: cover;
  position: relative;
  // margin-top: 50px;
}

.school_banner::before {
  content: '';
  position: absolute;
  background: transparent linear-gradient(104deg, #005182db 0%, #00518208 100%) 0% 0% no-repeat
    padding-box;
  opacity: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.school_banner_text {
  width: 50%;
}

.school_banner_text h2 {
  font-size: 42px;
  color: #fff;
}
.school_service {
  background: #002b459e;
  padding: 22px;
  margin-top: -6.625rem;
  position: relative;
}

.school_stepts .steps {
  border: 1px solid #cbebff;
  border-radius: 12px;
  padding: 16px 1px;
  min-height: 36vh;
}

.school_stepts {
  counter-reset: numbern;
}

.school_stepts .card::after {
  position: absolute;
  content: '1';
  background: #fff;
  right: 5px;
  /* height: 65px; */
  border: 1px solid #eff9ff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -9%;
  /* bottom: 0; */
  width: 43px;
  height: 43px;
  border-radius: 42px !important;
  font-size: 18px;
  font-family: 'KumbhSans-Bold';
  font-weight: 900;
  content: counter(numbern);
  counter-increment: numbern;
}

.School_about {
  background-color: #f8fcff;
  padding: 3rem 0px;
  margin-top: 2rem;
}

.school_about_img img {
  border-radius: 20px;
  position: relative;
  box-shadow: 11px 9px 1px 1px #009ff40a;
}
.exam_cards {
  background-image: url('../../images/school_list.jpg');
  height: 60vh;
  background-size: cover;
  position: relative;
}
// .exam_cards::before {
//   background-color: #005182de;
//   content: '';
//   position: absolute;
//   width: 100%;
//   top: 0;
//   bottom: 0px;
// }
.exams_cards {
  background: #009ff4;
  height: 185px;
  width: 237px;
  padding: 8px 8px 8px 32px;
  position: relative;
  border-radius: 6px;
}

.explore_card1 {
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.exams_cards p {
  position: absolute;
  /* right: -1px; */
  top: 76px;
  font-size: 14px;
  left: -45px;
  transform: rotate(-90deg);
  color: #fff;
}
.main_exam_cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.topstudent_slider .flag img {
  width: 20px;
}
.main_exam_cards .exams_cards:nth-child(2) {
  background-color: #67dd9c;
}
.main_exam_cards .exams_cards:nth-child(3) {
  background-color: #f9b121;
}
.main_exam_cards .exams_cards:nth-child(4) {
  background-color: #f66962;
}

/* School Landing page Ends  */

.why-ni-how .rightline::after {
  content: '';
  width: 1px;
  height: 60px;
  background-color: #d7e5ed;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

hr {
  background: #c7c7c7 !important;
}

/* Job Detal css starts */

.job_info {
  background: #f6fcff;
  border-radius: 17px;
}
.shout_text {
  background: #29ccff;
  text-align: center;
  font-size: 65px;
  color: #fff;
  width: 112px;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: GoudyStoutRegular;
  height: 112px;
}
.info-dark {
  background: #ecf8ff;
  color: #009ff4;
  border-radius: 13px;
  font-weight: normal;
}
.urgent-dark {
  background: #fff1f1;
  color: #f52e24;
  border-radius: 13px;
  font-weight: normal;
}
.apply_btn,
.apply_btn:hover {
  background: white;
  border-color: white;
  width: auto;
  border-radius: 7px;
  color: #f52e24;
  padding: 12px 22px;
  box-shadow: 0px 1px 8px #009ff436;
}
.f-block {
  align-items: center;
}
ul.job_dec_list {
  margin-left: 19px;
}

ul.job_dec_list li::marker {
  font-size: 31px;
}
.apply_btn_outline,
.apply_btn_outline:hover {
  background: white;
  border-color: #f52e24;
  width: auto;
  border-radius: 7px;
  color: #f52e24;
  padding: 12px 22px;
}
.contact_form.job_form input,
.contact_form.job_form textarea {
  border-radius: 7px !important;
}
.attach-block {
  text-align: center;
  border: 2px dotted #5bc6ff82;
  border-radius: 10px;
  width: 70%;
  margin-left: auto;
  padding: 41px;
  margin-top: 56px;
}
.cancel_lesson_modal.job_modal .btn-close {
  border: 1px solid #005182;
  color: #005182;
}
.banner_bg.job_bg_banner:before {
  content: '';
  background: url('../../images/job_banner_img.png') no-repeat;
  height: -webkit-fill-available;
  background-position: right top;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: auto;
  background-size: 56%;
}
.banner_text.job_banner_text {
  position: relative;
  background: #fff;
  border-radius: 30px;
  padding: 32px;
  box-shadow: 4px 7px 0px 4px #ffffff5e;
}
.select_group_block select {
  border: none !important;
  padding-left: 33px !important;
}
.select_listing_group .select_group_block {
  border-right: 1px solid #ececec;
  padding-left: 12px;
}
.select_group_block span {
  position: absolute;
  top: 8px;
}
.select_group_block select:nth-child(2) {
  padding-left: 20px;
}
.select_group_block {
  width: 158px;
}
.select_listing_group .select_group_block:last-child {
  border-right: 0px !important;
}
.shout_text_small {
  background: #29ccff;
  text-align: center;
  font-size: 65px;
  color: #fff;
  width: 62px;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: GoudyStoutRegular;
  height: 62px;
}
.applied_btn,
.applied_btn:hover {
  background: #67dd9c;
  border-color: #67dd9c;
  width: auto;
  border-radius: 7px;
  color: #fff;
  padding: 7px 13px;
}
.job_info.job_box {
  background: transparent;
  border: 1px solid #ceeeff;
}
.applied_btn,
.applied_btn:hover {
  background: #67dd9c;
  border-color: #67dd9c;
  width: auto;
  border-radius: 7px;
  color: #fff;
  padding: 7px 13px;
}
.shout_text_small.pink-info {
  background: #ff29b8;
}
.shout_text_small.light_violet {
  background: #c787ff;
}
.shout_text_small.light_yellow {
  background: #ffa200;
}
.select_listing_group {
  border: 1px solid #ececec;
  border-radius: 12px;
  padding: 9px;
}

.exam_cards {
  display: flex;
  align-items: center;
}

/* Job Detal css  Ends*/

/********** Career css  Starts************/
.career_banner {
  background: url('../../images/career-banner.png') no-repeat center bottom;
  height: 100vh;
  background-size: cover;
  position: relative;
}
.career_banner::before {
  content: '';
  position: absolute;
  background: transparent linear-gradient(104deg, #005182db 0%, #00518208 100%) 0% 0% no-repeat
    padding-box;
  opacity: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.career_hd_content {
  width: 50%;
}
.career_hd_content h2 {
  font-size: 42px;
}
.career_banner_btns {
  min-width: 200px;
}
.career_mission_contant h4,
.sm_head h4 {
  font-size: 18px;
  font-weight: bold;
  color: #67dd9c;
}
.career_mission_contant h4::before,
.sm_head h4::before {
  background: url('../../images/sm_hd_bg.png') no-repeat;
  left: 11px;
  height: 25px;
  content: '';
  width: 140px;
  position: absolute;
  top: 7px;
}
.career_mission_sec {
  padding: 100px 0;
}
.career_mission_contant {
  padding: 50px 0;
}
.bg_lt_yellow,
.bg_lt_yellow:hover {
  background: #faed98;
  border: 1px solid #faed98;
  box-shadow: none;
}
.career_work_sec {
  background: #fafafa;
  padding: 50px 0;
}
.sm_head {
  width: 800px;
  margin: 0 auto;
}
.work_vector {
  width: 100px;
  height: 100px;
  padding: 35px 20px 0;
  margin: 0 auto;
}
.bg_01 {
  background: url('../../images/01_bg.png') no-repeat;
  background-position: center center;
}
.bg_02 {
  background: url('../../images/02_bg.png') no-repeat;
  background-position: center center;
}
.bg_03 {
  background: url('../../images/03_bg.png') no-repeat;
  background-position: center center;
}
.career_blogs_sec {
  padding: 60px 0;
}
.career_news_sec {
  margin-bottom: 100px;
}
.number_section .container {
  margin-bottom: 60px;
  padding: 50px;
  border-radius: 30px;
  background: url('../../images/blue_map_bg.png') no-repeat;
  width: 100%;
  background-size: cover;
}
.number_hd h3 span {
  font-family: Chelsea-Regular;
  font-weight: 300;
}
.number_hd h3 {
  font-weight: 800;
}
.number_hd {
  margin: 0 auto;
  width: 100%;
  // width: 800px;
}
.number_count_card h1 {
  font-weight: 800;
}
.number_count_card {
  border-right: 3px solid #fff;
}
.news_head h4 {
  font-size: 18px;
  font-weight: bold;
  color: #67dd9c;
}
.news_head {
  width: 800px;
}
.join_wrap {
  z-index: 99;
  margin: 0 200px;
  background: #fff4f3;
  border-radius: 20px;
  padding: 50px 60px;
  position: relative;
  box-shadow: 0px 6px 5px 0px #f8e6e4;
  -webkit-box-shadow: 0px 6px 5px 0px #f8e6e4;
  -moz-box-shadow: 0px 6px 5px 0px #f8e6e4;
  margin-bottom: 50px;
}
.join_wrap::before {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  background: url('../../images/ready_mask_bg.png') no-repeat;
  content: '';
  width: 20%;
  height: 80%;
}
.join_us_wrap h2 {
  color: #f52e24;
  font-weight: 800;
}
.lt_grey_cl {
  color: #a6b0b7;
}
.join_btn {
  padding: 35px 0;
  text-align: right;
}
.career_news_card {
  height: 400px;
}
.news_feature_imgs {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.tag {
  background: #ff9a65;
  text-align: center;
  padding: 6px 12px;
  border-radius: 20px;
  width: auto;
  position: absolute;
  content: '';
  right: 3%;
  top: 3%;
}
.news_details {
  position: absolute;
  content: '';
  bottom: 0;
  padding: 15px;
  background: linear-gradient(360deg, rgba(2, 0, 36, 0.7) 0%, rgba(2, 0, 36, 0) 100%);
  border-radius: 0 0 20px 20px;
}

/********* Career css  End**********/

/* =========Ogranization css===========  */

.banner_bg.org:before {
  background: url('../../images/orgbannerimg.png') no-repeat;
  background-size: 56%;
  background-position: right center;
  z-index: 0;
}
.org_banner {
  z-index: 999;
  position: relative;
}
.team_content {
  position: relative;
  z-index: 99;
}
.why-ni-how .rightline::after {
  content: '';
  width: 1px;
  height: 60px;
  background-color: #d7e5ed;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.org-features .box-sec {
  border-radius: 30px;
  padding: 20px;
}

.org-features .box-sec.yellow-shadow {
  box-shadow: 4px 5px 0 #ffdcad;
}

.org-features .box-sec.orange-shadow {
  box-shadow: 4px 5px 0 #ffbaad;
}

.org-features .box-sec.green-shadow {
  box-shadow: 4px 5px 0 #adffef;
}

.org-features .box-sec.blue-shadow {
  box-shadow: 4px 5px 0 #adbdff;
}

.org-features .mt-80 {
  margin-top: 80px;
}

.org-empower {
  background: url('../../images/org-empower-bg.png') no-repeat center top;
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.org-empower .bannerText {
  background-color: rgba(0, 81, 130, 0.17);
  max-width: 680px;
  padding: 2.3rem;
}
.banner_bg.org {
  background-color: #fff;
}
.cultural_busi_sec {
  padding: 60px 0 0;
}
.cultural_busi_head {
  width: 650px;
}
.business_img img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 20px;
}
.business_title {
  bottom: -5px;
  content: '';
  right: 3%;
  background: #e8f7ff;
  padding: 8px 10px;
  border-radius: 30px;
  font-weight: 800;
  color: #009ff4;
}
.busi_flag {
  content: '';
  bottom: -18px;
  left: -5px;
  width: 60px;
  height: 60px;
  border-radius: 40px;
  background: #fff;
  padding: 20px 15px;
}
.busi_flag img {
  width: 30px;
  height: 20px;
  border-radius: 0;
}
.cultural_content h4 {
  font-family: KumbhSans-Bold;
}
.cultural_slider.owl-theme .owl-nav.disabled + .owl-dots {
  display: none;
}
.cultural_slider.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: block !important;
}
.cultural_slider.owl-theme .owl-nav {
  margin-top: -80px;
}
.cultural_slider.owl-carousel .owl-nav button.owl-next,
.cultural_slider.owl-carousel .owl-nav button.owl-prev {
  background: 0 0;
  color: inherit;
  width: 50px;
  height: 50px;
  border-radius: 0;
  padding: 0px;
  font: inherit;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.cultural_slider.owl-carousel .owl-nav button.owl-prev {
  border: 1px solid #dda7a4;
}
.cultural_slider.owl-carousel .owl-nav button.owl-prev span {
  font-size: 36px;
  padding: 0 10px;
  color: #dda7a4;
}
.cultural_slider.owl-carousel .owl-nav button.owl-next {
  border: 1px solid #f52e24;
}
.cultural_slider.owl-carousel .owl-nav button.owl-next span {
  font-size: 36px;
  padding: 0 10px;
  color: #f52e24;
}

.courses_slider.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: block !important;
}
.courses_slider.owl-theme .owl-nav {
  margin-top: 0px;
}
.courses_slider.owl-carousel .owl-nav button.owl-next,
.courses_slider.owl-carousel .owl-nav button.owl-prev {
  background: 0 0;
  color: inherit;
  width: 30px;
  height: 30px;
  border-radius: 0;
  padding: 0px;
  font: inherit;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.courses_slider.owl-carousel .owl-nav button.owl-prev span {
  font-size: 36px;
  padding: 0 10px;
  color: #dda7a4;
}
.courses_slider.owl-carousel .owl-nav button.owl-next span {
  font-size: 36px;
  padding: 0 10px;
  color: #f52e24;
}
.join_form_sec {
  background: #f9fdff;
  padding: 60px 0;
}
.join_form {
  margin: 0 160px;
}
.join_input .form-control {
  height: 50px;
}

/* ========= Ogranization css ends ===========  */

/* ========= Kids Landing page css Start ===========  */

.kids_banner {
  background: url('../../images/kids_bg.png') no-repeat center top;
  background-size: cover;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
}
.kids_banner .kids_banner_imges {
  position: absolute;
  right: 0px;
  top: 50px;
}
.kids_banner .kids_banner_imges img {
  width: 88%;
}

.kids_video_bg {
  background: url('../../images/kids_video_bg.png') no-repeat center top;
  background-size: cover;
  height: 40vh;
  display: flex;
  align-items: center;
}

.kids_video_bg .kvideo_text h4 {
  font-size: 34px;
}
.school_stepts.kids_stepts .learn_m a {
  color: #f66962;
}

.kids_banner .kids_boxes .kbox {
  height: 158px;
  width: 146px;
  margin-right: 2%;
  background-color: #d0f3ea;
  border: 6px solid #fff;
  border-radius: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 6px 11px 4px #d782821a;
}

.kids_banner .kids_boxes .kbox:nth-child(2) {
  background-color: #ffe9ed;
}
.kids_banner .kids_boxes .kbox:nth-child(3) {
  background-color: #cfeeff;
}

.kids_banner .kids_boxes .kbox p {
  padding: 1px 5px;
  padding-top: 20px;
  color: #656565;
  margin: 0 auto;
}

.school_stepts.kids_stepts .steps {
  border: 1px solid #ffe7d3;
}
.school_stepts.kids_stepts .steps:hover {
  box-shadow: 0px 6px 11px 4px #d782821a;
  border: none;
}
.school_stepts.kids_stepts .steps::after {
  display: none;
}
.kids_stepts .steps .kstep_no {
  background-color: #fdf6f0;
  height: 44px;
  width: 44px;
  border-radius: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'KumbhSans-Bold';
  color: #ffbd84;
  font-size: 20px;
  margin-bottom: 37px;
  padding: 26px;
}
.kids_bg_light {
  background-color: #fbf0f2 !important;
}
/* ========= Kids Landing page css Ends===========  */

.vert-scroll-block {
  height: 30vh;
  overflow-y: scroll;
}
.vert-scroll-block::-webkit-scrollbar {
  width: 4px;
}

.vert-scroll-block::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.vert-scroll-block::-webkit-scrollbar-thumb {
  background: #dbdbdb;
}

.vert-scroll-block::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}

.switch-text .dropdown-menu li {
  opacity: 4;
  padding: 0px;
}
.dropdown.switch-text button {
  background: transparent;
  border: 0px;
  color: #005182;
  padding: 0px 0px;
  font-size: 16px;
  width: 100%;
  text-align: left;
}
span.switch-text {
  font-size: 13px;
  color: #ff7c75;
  float: right;
}
/* ========= Tutor-part Landing page css===========  */
.profile_img.profile_teacher {
  width: 104px;
  height: 104px;
  position: relative;
}
.list-group-numbered > li::before {
  display: none;
}
.ambassador-signup::before {
  content: '';
  background: #00000021;
  width: 100%;
  height: 100%;
  position: absolute;
  /* overflow: hidden; */
  border-radius: 27px;
}

/* ===================================================
======================================================
=============Css by Sandeep 23-11-2021================
======================================================
=================================================== */

.dropdown .call-popup .notification_head p span {
  height: auto;
  width: auto;
  background-color: transparent;
}
.call-popup .form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
}
.call-popup .form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.dropdown .call-popup .student_lesson.incoming-call {
  background-color: #f1fcf6;
}
.call-popup .upcoming_event_tab .accordion-button {
  border: none !important;
  border-radius: none !important;
  padding: 0;
}
.call-popup .calendar_date {
  box-shadow: none;
  background: #0a9ef817;
  height: 65px;
  width: 65px;
}
.call-popup .student_lesson {
  flex: 1;
}
.inbox_popup.call-popup.show {
  height: 36vh;
}
.calender-filters .form-label {
  color: #005182;
  font-size: 16px;
  font-family: 'KumbhSans-Bold';
  margin-bottom: 10px;
}
.modal-footer.calender-filters .btn {
  width: unset;
}

.calender-filters .calender-filter-content {
  max-width: 90%;
  margin: auto;
  background-color: #fffbfb;
  padding: 15px;
}

/* Reservation link accordian css include here */
.accordion.reservation_link .accordion-button {
  border: none !important;
  padding: 0;
}

.lession_img_scroll {
  width: 100%;
  overflow-x: scroll;
}

.lession_img_scroll span img {
  width: 238px;
  height: 170px;
  border-radius: 14px;
  margin-right: 21px;
  margin-bottom: 21px;
  object-fit: cover;
}
/*************** carousel slider ******************/
.review_lesson small {
  font-size: 14px;
}
.review_lesson img {
  margin-top: -20px;
  margin-bottom: 10px;
}

.carousel-indicators.block-slider-list {
  /* position: absolute; */
  /* right: 0; */
  top: 0;
  left: 0px;
  z-index: 2;
  /* display: flex; */
  justify-content: left;
  /* padding: 0; */
  /* margin-right: 0; */
  /* margin-bottom: 0; */
  margin-left: 14px;
  list-style: none;
  overflow-x: scroll;
  width: 97%;
}
.carousel-indicators.block-slider-list [data-bs-target] {
  width: 94px;
  height: 53px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: inherit;
  opacity: 1;
}
.block-slider-list .carousel-item::after {
  background: transparent;
  content: '';
}
.block-slider-list button.active {
  border: 2px solid #000;
  margin-top: 10px;
}

.kids_tutor_profile .kids_tutor_img img {
  width: 58px;
  height: 58px;
  margin-right: 10px;
  border-radius: 52px;
}

.kids_tutor_profile .kidstutor_images_full img {
  width: 100%;
  height: 510px;
  object-fit: cover;
  border-radius: 14px;
}

.kids_tutor_profile .country_f img {
  width: 24px;
}

.welcome_kids_profile {
  background-color: #f4fbff;
  padding: 6px 11px;
}
.welcome_kids_profile h4 {
  font-size: 16px;
  font-weight: 600;
  color: #469ccc;
}

.became_kids .h_25 {
  height: 223px;
}
.became_kids a.theme_btn2 {
  margin-left: auto;
  display: flex;
  width: fit-content;
}
.bg-light {
  background-color: #f5f5f5;
}

.kids_profile_scroll {
  max-height: 84vh;
  overflow-y: scroll;
}

.price_amount {
  font-size: 26px;
  font-weight: 600;
  color: #f66962;
}

.plan_right_side .payment_radios.payment_option .payment_img {
  border: none;
  height: 32px;
}

/* Start of home page plan card css */

.plan_right_side
  .profile_info.payment_radios.payment_option
  .profile_info
  .form-check-label
  :hover {
  background-color: #000;
}
.plan_right_side
  .profile_info.payment_radios.payment_option
  .form-check-input:checked
  + .form-check-label {
  background: #f2f9ff !important;
  border-color: #0a9ef8 !important;
}
/* endofof home page plan card css */

.broker_head_new {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 2;
}

.contentCenter {
  height: calc(100vh - 70px);
  width: 400px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4c4c4c;
}

.errorBorder {
  border-color: red !important;
}

input,
textarea,
select {
  &.errorBorder {
    border-color: red !important;
  }
}

.show_pdf {
  position: relative;
  span.icon_red {
    position: absolute;
    right: 0;
    top: -13px;
  }
  svg path {
    fill: red;
  }
}
.certificate {
  position: relative;
  .certificate_upload {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
  }
}

.video_section {
  border: 1px dotted red;
  border-radius: 8px;
  max-width: 33.33%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 5px 5px;
  span {
    word-break: break-all;
  }
}

.delete_icon_edit {
  position: absolute;
  // right: 84%;
  /* left: 0px; */
  top: -20px;
  cursor: pointer;

  svg path {
    fill: red;
  }
}
.teacher_video_uploader input {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  opacity: 0 !important;
}
.video_box span {
  position: absolute;
  right: 0px;
}
.video_box span {
  position: absolute;
  right: 3px;
  background: #a70c0c;
  border-radius: 100px;
  padding: 1px;
  width: 25px;
  height: 25px;
  /* text-align: -webkit-center; */
  top: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  .delete_icon {
    color: #fff;
  }
}
// .video_box span a {
//   color: #fff;
// }
.feature_box1 {
  -webkit-box-shadow: 6px 8px 4px 0px rgba(255, 220, 173, 1);
  -moz-box-shadow: 6px 8px 4px 0px rgba(255, 220, 173, 1);
  box-shadow: 6px 8px 4px 0px rgba(255, 220, 173, 1);
  border-radius: 32px;
}
.feature_box2 {
  -webkit-box-shadow: 6px 8px 4px 0px rgba(173, 255, 239, 1);
  -moz-box-shadow: 6px 8px 4px 0px rgba(173, 255, 239, 1);
  box-shadow: 6px 8px 4px 0px rgba(173, 255, 239, 1);
  border-radius: 32px;
}
.feature_box3 {
  -webkit-box-shadow: 6px 8px 4px 0px rgba(255, 186, 173, 1);
  -moz-box-shadow: 6px 8px 4px 0px rgba(255, 186, 173, 1);
  box-shadow: 6px 8px 4px 0px rgba(255, 186, 173, 1);
  border-radius: 32px;
}
.feature_box4 {
  -webkit-box-shadow: 6px 8px 4px 0px rgba(173, 189, 255, 1);
  -moz-box-shadow: 6px 8px 4px 0px rgba(173, 189, 255, 1);
  box-shadow: 6px 8px 4px 0px rgba(173, 189, 255, 1);
  border-radius: 32px;
}

.empower_div {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empower_box {
  position: absolute;
  z-index: 100;
  color: #fff;
  width: 55%;
  padding: 30px 60px;
  text-align: center;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(1px);
  -webkit-box-shadow: -5px -5px 1px -1px rgba(192, 203, 208, 0.52);
  -moz-box-shadow: -5px -5px 1px -1px rgba(192, 203, 208, 0.52);
  box-shadow: -5px -5px 1px -1px rgba(192, 203, 208, 0.52);
}

.team_cards {
  border-radius: 20px;
}
.h_70 {
  min-height: 70% !important;
}
.center_headingg {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
}

.team_pic {
  position: relative;
}

.team_flag {
  position: absolute;
  bottom: 153px;
  left: 4px;
  background: #fff;
  height: 44px;
  width: 50px;
  border-radius: 21px;
}

.center_pic {
  margin-top: 10px;
}

.theme_btnbt {
  background: #f66962;
  color: white;
  border-color: #ff554b;
  padding: 11px 80px;
  border-radius: 4px;
  box-shadow: 0px 4px 15px #98070026;
  font-size: 18px;
}

.emp-img {
  height: 500px;
}

@media screen and (max-width: 450px) {
  .emp-img {
    height: 500px;
  }
  .empower_box {
    position: absolute;
    z-index: 100;
    color: #fff;
    width: 70%;
    padding: 26px 24px;
    text-align: center;
    border-radius: 20px;
    background: transparent;
  }
}
@media screen and (max-width: 768px) {
  .team_flag {
    display: none;
  }
}
.schools_banner {
  background: url('../../images/school_single_bannner.jpg') no-repeat center bottom;
  height: 70vh;
  background-size: cover;
  position: relative;
  margin-top: 90px;
}
.schools_banner::before {
  content: '';
  position: absolute;
  background: transparent linear-gradient(104deg, #005182db 0%, #00518208 100%) 0% 0% no-repeat
    padding-box;
  opacity: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.step_cards {
  border: 1px solid #d0edff;
  border-radius: 35px;
}
.join_us_box {
  background: #fff4f3;
  border-radius: 25px;
}
.text-red {
  color: red;
  font-weight: 700;
}
.custom_card {
  box-shadow: 0px 0px 12px 0px rgba(0, 159, 244, 0.15);
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 159, 244, 0.15);
  -moz-box-shadow: 0px 0px 12px 0px rgba(0, 159, 244, 0.15);
  border-radius: 20px;
  margin-bottom: 30px;
  background: #fff;
}
.custom_card_hd {
  border-bottom: 1px solid #efefef;
  padding: 15px 20px;
  margin-bottom: 0px;
}
.custom_card_body {
  padding: 12px 16px;
}
.filter_btnn {
  background: #f66962;
  color: white;
  border-color: #ff554b;
  padding: 8px 64px;
  border-radius: 4px;
  box-shadow: 0px 4px 15px #98070026;
  font-size: 18px;
}
.clearFilter_btnn {
  background: #f4fbff;
  color: #111;
  padding: 8px 25px;
  border-radius: 4px;
  font-size: 18px;
  border: 1px solid;
}
.btn_sizee {
  padding: 7px 15px;
  border-radius: 3px;
  margin-bottom: 15px;
  color: black !important;
  border: 1px solid #c7c5c5b8;
  position: absolute !important;
}
.react-tel-input .form-control {
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px !important;
  // min-width: 16vw !important;
  outline: none;
  padding: 10px 64px !important;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
  height: 50px !important;
  margin-top: 37px;
}
.react-tel-input .special-label {
  position: absolute;
  z-index: 1;
  top: -9px;
  left: 44px;
  display: block;
  background: white;
  padding: 0 5px;
  font-size: 13px;
  white-space: nowrap;
  color: #005182;
}
.react-tel-input .country-list .country-name {
  margin-right: 16px;
  margin-left: 25px;
}
.jobs_banner {
  background: url('../../images/career-banner.png') no-repeat center bottom;
  height: 70vh;
  background-size: cover;
  position: relative;
  margin-top: 90px;
}

.jobs_banner::before {
  content: '';
  position: absolute;
  background: transparent linear-gradient(104deg, #005182db 100%, #00518208 100%) 0% 0% no-repeat
    padding-box;
  opacity: 0.6;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.jobs_banner_text {
  z-index: 5;
}
.translateWidth {
  width: 100%;
}
.marginTop {
  margin-top: 60%;
}
.jobs_btn {
  background: #67dd9c;
  border-color: #67dd9c;
  color: white;
  width: 206px;
  border-radius: 5px;
  box-shadow: 0px 6px 9px #16a45617;
  height: 100%;
  margin-right: 10px;
  padding: 13px;
  cursor: pointer;
}
.jobs_btn1 {
  background: #009ff4;
  border-color: #009ff4;
  color: white;
  width: 206px;
  border-radius: 5px;
  box-shadow: 0px 6px 9px #16a45617;
  height: 100%;
  margin-right: 10px;
  padding: 13px;
  cursor: pointer;
}
.jobs_btn2 {
  background: #fa9898;
  border-color: #fa9898;
  color: white;
  width: 206px;
  border-radius: 5px;
  box-shadow: 0px 6px 9px #16a45617;
  height: 100%;
  margin-right: 10px;
  padding: 13px;
  cursor: pointer;
}
.dassh_bg {
  background: #fafdff;
}

.team_car {
  height: 200px;
  width: 100%;
}
.inner-cards {
  padding: 16px 16px 20px;
}

.read_more_text {
  font-size: 18px;
  color: #21abf5;
  font-weight: 500;
  margin-left: 3px;
  cursor: pointer;
}

.red1_btn {
  background: transparent;
  color: red;
  border-color: red;
  padding: 10px 35px;
  font-size: 18px;
}
.news_pic {
  // border: 1px solid red;
  position: relative;
}
.news_box {
  position: absolute;
  top: 10px;
  right: 25px;
  background: #ff9a65;
  padding: 10px 24px;
  color: #fff;
  border-radius: 23px;
}
.news_boxx {
  position: absolute;
  top: 10px;
  right: 35px;
  background: #ff9a65;
  padding: 10px 24px;
  color: #fff;
  border-radius: 23px;
}
.news_headd {
  // border: 1px solid blue;
  margin-top: -105px;
}

.news_headd h6 {
  color: #fff;
  z-index: 5;
}
.news_desc {
  z-index: 5;
  margin-top: 26px;
  position: absolute;
  bottom: -52px;
  color: #fff;
  left: 26px;
}
.all_jobs {
  // border: 1px solid red;
  margin-top: 100px !important;
  // position: relative;
}
.all_jobs_banner {
  width: 90%;
  height: 85%;
  position: relative;
}
.jobs_filter {
  position: absolute;
  background: #fff;
  top: 50%;
  width: 36%;
  border-radius: 18px;
  -webkit-box-shadow: 6px 8px 4px 0px rgba(251, 251, 251, 1);
  -moz-box-shadow: 6px 8px 4px 0px rgba(251, 251, 251, 1);
  box-shadow: 6px 8px 4px 0px rgba(251, 251, 251, 1);
  border-radius: 32px;
}
.jobs_filter_options {
  border: 1px solid #e8e3e3;
  border-radius: 15px;
}
.new_select {
  border: none !important;
}
.feature_job h2 {
  margin-top: -140px;
}
.job_card {
  // border: 1px solid red;
  display: flex;
  justify-content: space-between;
}
.job_cards {
  border: 1px solid #ceeeff;
  width: 48%;
  display: flex;
  border-radius: 27px;
}
.job_thumbnail {
  width: 15%;
  // border: 1px solid blue;
}
.job_description {
  width: 55%;
  // border: 1px solid blue;
}
.job_btn {
  width: 30%;
  // border: 1px solid blue;
}
.job_type {
  display: flex;
}

.job_btn1 {
  background: transparent;
  color: red;
  border-color: red;
  padding: 7px 14px;
  font-size: 16px;
  border-radius: 12px;
}

@media screen and (max-width: 1600px) {
  .jobs_filter {
    width: 50%;
  }
}
@media screen and (max-width: 1000px) {
  .jobs_filter {
    width: 45%;
  }
}

@media screen and (max-width: 1200px) {
  .feature_job {
    margin-top: 150px;
  }
}
@media screen and (max-width: 992px) {
  .feature_job {
    margin-top: 350px;
  }
  .jobs_filter {
    width: 65%;
  }
}

@media screen and (max-width: 768px) {
  .feature_job {
    margin-top: 550px;
  }
  .jobs_filter {
    width: 65%;
  }
  .job_cards {
    width: 90%;
  }
}
@media screen and (max-width: 450px) {
  .feature_job {
    margin-top: 600px;
  }
  .jobs_filter {
    width: 85%;
  }
  .job_cards {
    width: 95%;
  }
}

.job_box {
  border: 1px solid #ceeeff;
  width: 100%;
  display: flex;
  border-radius: 27px;
  background: #f6fcff;
}
.job_btn2 {
  background: #fff;
  color: red;
  border-color: #f3ecec !important;
  padding: 18px 34px;
  font-size: 18px;
  border-radius: 6px;
  margin-bottom: 18px;
  box-shadow: 1px 2px 6px 2px rgba(255, 255, 255, 0.75);
}
.job_type1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.label_css {
  font-size: 17px;
  font-weight: 600;
}

.create_new_job {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background: #feeeee; */
  height: 223px;
  border-radius: 22px;
  position: relative;
  overflow: hidden;
  border: 1px dotted #29ccff;
  width: 70%;
  .show_image {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hidden_input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
  }
}
.badge {
  display: inline-block;
  padding: 10px 20px !important;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
@media screen and (max-width: 768px) {
  .card_pro {
    margin-top: 50px !important;
  }
  .new_img {
    height: 400px;
  }
}
.video_box {
  background: #f8f9fa;
  margin-top: 100px;
  height: 100%;
  width: 100%;
  margin-bottom: 20px;
}
.video_box2 {
  background: #f8f9fa;
  padding-top: 50px;
  padding-bottom: 20px;
  height: 100%;
  width: 100%;
  margin-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
}
.video_div_center .box1 {
  background: #fff;
  height: 200px;
  width: 400px;
  border-radius: 20px;
}
.video_div_center .box2 {
  background: #fff;
  height: 200px;
  width: 400px;
  border-radius: 20px;
}
.new_inp {
  border-radius: 34px !important;
  padding: 15px 15px !important;
  color: #005182 !important;
}
.link_box {
  height: 200px;
  width: 200px;
  border: 1px solid #d3d1d1;
  border-radius: 12px;
  cursor: pointer;
}
.link_header {
  border-bottom: 1px solid #d3d1d1;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.link_footer {
  height: 70px;
}
.links_section {
  margin-top: 150px;
}
.link_btnn,
.link_btnn:hover {
  background: #ff554b;
  color: white;
  border-color: #ff554b;
  padding: 6px 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 15px #98070026;
  font-size: 18px;
}
.red_btnn {
  padding: 10px 0;
  min-width: 380px;
  background: #f66962;
  color: #fff;
  font-weight: 800;
  text-decoration: none;
  border: none;
  margin: 0 auto;
}
.payment_width {
  width: 60%;
}
.remote_video_size {
  height: 100%;
  width: 38vw;
}
.local_video_size {
  height: 100%;
  width: 38vw;
}
.nav_tab_width {
  width: 7vw;
  display: flex;
  justify-content: center;
}
.display {
  display: flex;
}
.width {
  width: 49%;
}
.tutor_filter {
  display: flex;
}
@media (max-width: 500px) {
  .video_call_size {
    height: 100%;
    width: 90vw;
  }
  .react-pdf__Page__textContent .react-pdf__Page__canvas {
    width: 70vw !important;
  }
  .remote_video_size {
    height: 100%;
    width: 90vw;
  }
  .local_video_size {
    height: 100%;
    width: 90vw;
  }
}
@media (max-width: 1500px) {
  .tutor_filter {
    display: block;
    z-index: 10;
  }
  .tutor_filter_item {
    width: 175px;
    margin-bottom: 5px;
    text-align: center;
  }
  .payment_width {
    width: 75%;
  }
}
@media (max-width: 1000px) {
  .width {
    width: 100%;
    margin-bottom: 10px;
  }
  .display {
    display: block;
  }
  .payment_width {
    width: 70%;
  }
}
.green_bg {
  background: #67dd9c;
}
.btn_size {
  padding: 10px 25px;
  border-radius: 30px;
  margin-bottom: 15px;
}
.ttc {
  text-transform: capitalize;
}
.cp {
  cursor: pointer;
}
.text-upercase {
  text-transform: capitalize;
}
.fc-daygrid-day-events {
  display: flex !important;
  // overflow: hidden;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  // overflow: ;
}
.tutor_list {
  max-height: 400px;
  overflow-y: scroll;
}
// .fc-daygrid-day .fc-day .fc-day-thu .fc-day-past {
//   height: 50px !important
// }
// .fc-day-past{  height: 50px !important;background-color : blue !important}
//  .fc-day-future{ background-color : blue !important }
//  .fc-day-today{ background-color : blue !important }
// .fc-daygrid-day{
//   height: 0px !important;
// }

.fc-day-pastt {
  max-height: 30px !important;
}
::-webkit-scrollbar {
  width: 8px; // for vertical scroll bar
  height: 8px; // for horizontal scroll bar
}

.fc .fc-daygrid-day.fc-day-past.fc-day-past-t {
  background-color: #e0eef7 !important;
}

.fc .fc-daygrid-day.fc-day-future.fc-day-future-t {
  background-color: #94e594 !important;
}

.fc .fc-daygrid-day.fc-day-today.fc-day-today-t {
  background-color: #fbfbbb !important;
}
.counter_icon {
  position: absolute !important;
  bottom: 28px !important;
  left: 23px !important;
  height: 24px !important;
  width: 20px !important;
  font-size: 14px !important;
}
.isUnreadChat {
  background: #e5f5ff;
  padding: 4px;
}
.isBackground {
  background: #e2e2e7;
  padding-top: 12px;
}
.red_btn,
.red_btn:hover {
  padding: 10px 0;
  min-width: 150px;
  background: #f66962;
  color: #fff;
  font-weight: 800;
  text-decoration: none;
  border: none;
}
.upload_cover input {
  top: 0;
  height: 300px;
  width: 330px;
  opacity: 0;
  cursor: pointer;
}
.upload_covers input {
  top: 0;
  height: 200px;
  width: 270px;
  opacity: 0;
  cursor: pointer;
}

.upload_cover_box {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='11' ry='11' stroke='%23F52E24FF' stroke-width='3' stroke-dasharray='6%2c 12' stroke-dashoffset='20' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 11px;
  background-color: #fff6f6;
  height: 300px;
  width: 330px;
}
.upload_cover_boxs {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='11' ry='11' stroke='%23F52E24FF' stroke-width='3' stroke-dasharray='6%2c 12' stroke-dashoffset='20' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 11px;
  background-color: #fff6f6;
  height: 200px;
  width: 270px;
}
.upload_syallabus input {
  left: 0;
  opacity: 0;
  width: 100%;
  height: 50px;
  top: 0;
}
.upload_syallabus {
  padding: 10px;
  border-radius: 8px;
  width: 99%;
  left: 0;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23F52E24FF' stroke-width='2' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
}
.syllabus_box {
  background: #fafdff;
  padding: 15px 25px;
}
.table-responsive::-webkit-scrollbar:horizontal {
  height: 9px;
}
td.fc-other-month {
   visibility: hidden;
}
.active-border{
  border: 1px solid #2AA3F8;
}